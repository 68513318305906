import { AddIcon } from "@chakra-ui/icons";
import { 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button, 
  useDisclosure, 
  Accordion, 
  AccordionItem, 
  AccordionButton, 
  Box, 
  Flex, 
  AccordionIcon, 
  AccordionPanel, 
  Text, 
  Image, 
  useToast,
  AlertIcon,
  Alert,
  Select,
  Link
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TaxlyInput } from "../../../../../components/Input";
import { getClientCompanies } from "../../../../../_services/company.service";
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import { getAllObligations, postSchedule } from "../../_services/kanban.service";
import { AutomationSelectCompanies } from "./AutomationSelectCompanies";
import { IObligationModifiedList, IObligationResponseCustom, TJurisdiction } from "../../interfaces/IObligation";
import { AutomationSelectObligations } from "./AutomationSelectObligations";
import { ScheduleRules } from './ScheduleRules'
import { IData, IDataMeta } from "../../../../../components/Table/table.interface";
import { SelectCompanies } from "../../../../../modal/SelectCompanies";

interface IAutomationCreateModalProps {
  guid_client: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AutomationCreateModal = (props: IAutomationCreateModalProps) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [automationName, setAutomationName] = useState<string>("");
  const [currentStep, setCurrentStep] = useState<number>(0);

  // Obligations
  const [darfs, setDarfs] = useState<IObligationResponseCustom[]>([]);
  const [darfsSelecteds, setDarfsSelecteds] = useState<IData|null>(null);
  const [obligationIsLoading, setObligationIsLoading] = useState<boolean>(false);
  const [obligationChecked, setObligationChecked] = useState<string[]>([]);
  const [fixedRule, setFixedRule] = useState<string>("toAntecipate");

  // Company
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [companyIsLoading, setCompanyIsLoading] = useState<boolean>(false);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false)

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const validate = (): boolean => {
    if(darfsSelecteds === null || darfsSelecteds.data.length === 0) {
      toast({
        title: 'Campos inválidos',
        description: 'Você pelo menos precisa ter uma obrigação para criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(companyChecked.length === 0) {
      toast({
        title: 'Sem empresas selecionadas',
        description: 'Você pelo menos precisa ter uma empresa selecionada para criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    const obligationsToValidate = darfsSelecteds.data;

    // Validar todos os campos
    let noCollaborators = false;
    let noTitle = false;
    let noDuedate = false;
    let noScheduleDate = false;
    let noJurisdiction = false;
    let noFrequency = false;
    obligationsToValidate.map((obligation) => {
      if(!obligation.collaborators || obligation.collaborators.length === 0) {
        noCollaborators = true;
      }

      if(!obligation.guid_darf && !obligation.dueDate) {
        noDuedate = true;
      }

      if(obligation.dueDate === 'specifydate' && !obligation.scheduleDate) {
        noScheduleDate = true;
      }

      if(!obligation.title) {
        noTitle = true;
      }

      if(!obligation.jurisdiction) {
        noJurisdiction = true;
      }

      if(!obligation.jurisdiction) {
        noJurisdiction = true;
      }

      if(!obligation.frequency) {
        noFrequency = true;
      }
    });

    if(noJurisdiction) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de uma jurisdição.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noFrequency) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de uma Frequência.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noCollaborators) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de um responsável.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noTitle) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam ter nomes que as identificam.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noDuedate) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam ter uma regra de replicação definida.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noScheduleDate) {
      toast({
        title: 'Campos inválidos',
        description: 'Obrigações com datas específicas precisam de ter um dia definido.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    return true;
  }

  const lastStep = () => {
    if(validate()) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }

  const fetchCompanies = async () => {
    setCompanyIsLoading(true);
    const companyList = await getClientCompanies(props.guid_client);

    if(companyList.status === 200) {
      setAllCompanies(companyList.response as IClientCompanies[]);
    }

    setCompanyIsLoading(false);
  }

  const fetchObligation = async () => {
    setObligationIsLoading(true);
    const {status, response} = await getAllObligations(props.guid_client);
    if(status === 200 && 'darfs' in response) {
      const darfs = response.darfs.map((obligation) => {
        return {
          ...obligation,
          jurisdiction: 'federal' as TJurisdiction,
          frequency: 'controlled',
          collaborators: [],
          isDatatablesEditable: false,
          datatablesIdentify: obligation.guid_darf
        }
      });

      setDarfs(darfs);
    }
    setObligationIsLoading(false);
  }

  useEffect(() => {
    if(isOpen && props.guid_client) {
      fetchCompanies();
      fetchObligation();
      setCurrentStep(0);
      setAutomationName('');
      setCompanyChecked([]);
      setObligationChecked([]);
    }
  }, [isOpen, props.guid_client])

  useEffect(() => {
    const meta: IDataMeta = {
      currentPage: 1,
      isFirstPage: true,
      isLastPage: true,
      nextPage: null,
      pageCount: 1,
      previousPage: null,
      totalCount: 0
    }

    let selectedsDarfs = darfs.filter(obligation => obligation.guid_darf && obligationChecked.includes(obligation.guid_darf)) as any[];
    selectedsDarfs = selectedsDarfs.map((darf) => {
      return {
        ...darf,
        isDatatablesEditable: false,
        datatablesIdentify: darf.guid_darf
      }
    })

    setDarfsSelecteds({
      data: selectedsDarfs,
      meta
    });
  }, [obligationChecked]);

  const handleEnterPressedOnNameSchedule = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter') {
      nextStep();
    }
  }

  const createSchedule = async () => {
    if(!validate()) {
      return;
    }

    const obligationsToSave: IObligationModifiedList[] = [];

    darfsSelecteds?.data.map((obligation) => {
      let day = obligation.scheduleDate
        ? new Date(obligation.scheduleDate).getUTCDate()
        : '';

      if(obligation.dueDate === 'specifydate') {
        day = obligation.scheduleDate;
      }

      obligationsToSave.push({
        guid_darf: obligation.guid_darf ?? '',
        day: String(day),
        month: '',
        code: obligation.code ?? '',
        title: obligation.title,
        rule: !obligation.guid_darf ? obligation.dueDate : "",
        jurisdiction: obligation.jurisdiction,
        generatingFactor: !obligation.guid_darf ? null : obligation.generatingFactor,
        frequency: obligation.frequency,
        isNewLine: !obligation.guid_darf ? true : false,
        isDateRedefined: false,
        isWeekday: true,
        canEditDay: !obligation.guid_darf ? true : false,
        collaborators: obligation.collaborators,
        slug: obligation.scheduleDarfsSlugs?.slug ?? null,
      })
    });

    const { status } = await postSchedule(props.guid_client, {
      scheduleName: automationName,
      autoGenerate: true,
      companies: companyChecked,
      fixedRule,
      obligations: obligationsToSave as IObligationModifiedList[],
    });

    if (status === 200) {
      toast({
        title: 'Agendamento e cards criados com sucesso',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });

      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro ao tentar registrar este agendamento',
        description: 'Ocorreu um erro ao tentar registrar este agendamento, por favor, tente mais tarde.',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
  }

  return (
    <>
      <Button 
        leftIcon={<AddIcon />} 
        bgColor="#4B4EFF" 
        color="white" 
        onClick={onOpen} 
        _hover={{ bg: '#282be0' }}
      >
        Criar agendamento
      </Button>

      <Modal 
        closeOnOverlayClick={false} 
        isOpen={isOpen} 
        onClose={onClose} 
        size="6xl"
      >
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent 
          flexGrow={1} 
          flexShrink={1} 
          borderLeft="12px solid #0263FF" 
          borderRadius={8} 
          maxW={1300}
        >
          <ModalHeader pl={4} fontFamily="Poppins-SemiBold" pb={3}>
            Novo agendamento - Obrigações Acessórias
          </ModalHeader>

          <ModalCloseButton />

          <Box style={{ display: currentStep < 4 ? 'block' : 'none' }}>
            <ModalBody pl={0} pr={0} fontFamily="Poppins-medium" fontSize={12} pb={6}>
              <Accordion index={currentStep} allowToggle>
                <AccordionItem border="none">
                  <h2>
                    <AccordionButton bg="white" p={4} onClick={() => { setCurrentStep(0) }}>
                      <Box as="span" flex="1" textAlign="left">
                        <Flex alignItems="center" position="relative">
                          <Text
                            fontFamily="Poppins-Medium"
                            color={"#171923"}
                            fontSize={14}
                          >
                            Passo 1: Preencha os dados da tarefa
                          </Text>
                        </Flex>

                        {currentStep !== 0 && (
                          <Flex alignItems="center" position="relative" mt={2}>
                            <Text
                              fontFamily="Poppins-Medium"
                              color={"#4B4EFF"}
                              fontSize={14}
                              mr={2}
                            >
                              {automationName}
                            </Text>

                            <Flex onClick={() => setCurrentStep(0)} flexDirection="row">
                              <Image src="/icons/edit-inline.svg" w="18px" />
                              <Text color="#171923" fontSize={"12px"}>Editar</Text>
                            </Flex>
                          </Flex>
                        )}
                      </Box>

                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={0}>
                    <TaxlyInput
                      placeholder="Nome do agendamento"
                      label={'Nome do agendamento'}
                      labelCustomStyle={{ mb: 1 }}
                      isRequired={true}
                      onKeyPress={handleEnterPressedOnNameSchedule}
                      onChange={(e) => { setAutomationName(e.target.value) }}
                    />

                    <Flex pb={4} w="100%" justifyContent="end">
                      <Button
                        fontSize="12px"
                        width="68px"
                        bgColor="#4B4EFF"
                        color="white"
                        _hover={{ bg: '#282be0' }}
                        mt="15px"
                        onClick={nextStep}
                        fontWeight="lighter"
                      >
                        Avançar
                      </Button>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderTop={'1px solid #CBD5E0'} borderBottom={'1px solid #CBD5E0'}>
                  <h2>
                    <AccordionButton bg="white" p={4} onClick={() => { if(automationName){ setCurrentStep(1) }}}>
                      <Box as="span" flex="1" textAlign="left">
                        <Flex alignItems="center" position="relative">
                          <Text
                            fontFamily="Poppins-Medium"
                            color={"#171923"}
                            fontSize={14}
                          >
                            Passo 2: Selecione as empresas que você gostaria de acompanhar usando os filtros abaixo
                          </Text>
                        </Flex>

                        {currentStep > 1 && companyChecked.length > 0 && (
                          <Flex alignItems="center" position="relative" mt={2} >
                            <Text
                              fontFamily="Poppins-Medium"
                              color={"#4B4EFF"}
                              fontSize={14}
                              mr={2}
                            >
                              {
                                companyChecked.length === 1
                                  ? `${companyChecked.length} empresa selecionada`
                                  : `${companyChecked.length} empresas selecionadas`
                              }
                            </Text>

                            <Flex onClick={() => setCurrentStep(1)}>
                              <Image src="/icons/edit-inline.svg" w="18px" />
                              <Text color="#171923" fontSize={"12px"}>Editar</Text>
                            </Flex>
                          </Flex>
                        )}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={4}>
                    <AutomationSelectCompanies
                      guid_client={props.guid_client}
                      companies={allCompanies}
                      isLoading={companyIsLoading}
                      setSelecteds={setCompanyChecked}
                      selecteds={companyChecked}
                    />

                    <Flex w="100%" justifyContent="end">
                      <Button
                        fontSize="12px"
                        width="68px"
                        bgColor="#4B4EFF"
                        color="white"
                        _hover={{ bg: '#282be0' }}
                        mt="15px"
                        isDisabled={companyChecked.length === 0}
                        onClick={nextStep}
                      >
                        Avançar
                      </Button>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem borderTop={'1px solid #CBD5E0'} borderBottom={'1px solid #CBD5E0'} >
                  <h2>
                    <AccordionButton bg="white" p={4} onClick={() => { if(automationName && companyChecked.length > 0){ setCurrentStep(2) }}}>
                      <Box as="span" flex="1" textAlign="left">
                        <Flex alignItems="center" position="relative">
                          <Text
                            fontFamily="Poppins-Medium"
                            color={"#171923"}
                            fontSize={14}
                          >
                            Passo 3: Selecione as obrigações acessórias federais
                          </Text>
                        </Flex>

                        {currentStep > 2 && (
                          <Flex alignItems="center" position="relative" mt={2} >
                            <Text
                              fontFamily="Poppins-Medium"
                              color={"#4B4EFF"}
                              fontSize={14}
                              mr={2}
                            >
                              {
                                darfsSelecteds?.data?.filter((darf) => darf.isDatatablesEditable === false).length === 1
                                  ? `${darfsSelecteds?.data?.filter((darf) => darf.isDatatablesEditable === false).length} obrigação selecionada`
                                  : `${darfsSelecteds?.data?.filter((darf) => darf.isDatatablesEditable === false).length} obrigações selecionadas`
                              }
                            </Text>

                            <Flex onClick={() => setCurrentStep(2)}>
                              <Image src="/icons/edit-inline.svg" w="18px" />
                              <Text color="#171923" fontSize={"12px"}>Editar</Text>
                            </Flex>
                          </Flex>
                        )}
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={4}>
                    <AutomationSelectObligations
                      guid_client={props.guid_client}
                      obligations={darfs}
                      isLoading={obligationIsLoading}
                      setSelecteds={setObligationChecked}
                      selecteds={obligationChecked}
                    />

                    <Flex w="100%" justifyContent="end">
                      <Button
                        fontSize="12px"
                        width="68px"
                        bgColor="#4B4EFF"
                        color="white"
                        _hover={{ bg: '#282be0' }}
                        mt="15px"
                        isDisabled={companyChecked.length === 0}
                        onClick={nextStep}
                      >
                        Avançar
                      </Button>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>

                <AccordionItem border="none" >
                  <h2>
                    <AccordionButton bg="white" p={4} onClick={() => { if(automationName && companyChecked.length > 0){ setCurrentStep(3) }}}>
                      <Box as="span" flex="1" textAlign="left">
                        <Flex alignItems="center" position="relative">
                          <Text
                            fontFamily="Poppins-Medium"
                            color={"#171923"}
                            fontSize={14}
                          >
                            Passo 4: Definir regras de agendamento automático
                          </Text>
                        </Flex>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={4}>
                    <ScheduleRules
                      guid_client={props.guid_client}
                      obligations={darfsSelecteds}
                      setObligations={setDarfsSelecteds}
                      isLoading={obligationIsLoading}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </ModalBody>

            <ModalFooter borderTop={'1px solid #CBD5E0'}>
              <Button
                bgColor="white"
                color="#171923"
                _hover={{ bg: 'white', textDecoration: 'underline' }}
                onClick={onClose}
                mr={5}
              >
                Fechar
              </Button>

              <Button
                bgColor="#4B4EFF"
                color="white"
                disabled={currentStep !== 3 || companyChecked.length === 0 || !automationName || darfsSelecteds?.data.length === 0}
                width={104}
                height={37}
                _hover={{ bg: '#282be0' }}
                onClick={lastStep}
              >
                Avançar
              </Button>
            </ModalFooter>
          </Box>


          <Box style={{ display: currentStep === 4 ? 'block' : 'none' }}>
            <ModalBody fontFamily="Poppins-medium" fontSize={12} p={4}>
              <h1 style={{ fontSize: '18px', fontWeight: 600, marginBottom: '15px' }}>
                Revisão das informações
              </h1>

              <Flex flexDirection="row" gap={4} >
                <Flex width="75%">
                  <TaxlyInput
                    placeholder="Nome do agendamento"
                    label={'Nome do agendamento'}
                    labelCustomStyle={{ mb: 1 }}
                    value={automationName}
                    borderColor="gray.300"
                    isRequired={true}
                    onChange={(e) => { setAutomationName(e.target.value) }}
                  />
                </Flex>

                <Flex width="25%" alignItems="end">
                  <Flex
                    border="1px solid"
                    fontSize="12px"
                    w={"100%"}
                    borderColor="gray.300"
                    borderRadius={5}
                    h="40px"
                  >
                    <Link
                      width="100%"
                      color="#4B4EFF"
                      h="40px"
                      fontWeight="500"
                      alignItems="center"
                      onClick={() => setOpenSelectCompanies(true)}
                    >
                      <Flex gap={2} pl={2.5} justifyContent={'start'} alignItems="center" h="40px">
                        <Image src="/icons/company.svg" w="24px" />

                        {
                          companyChecked.length > 0
                            ? companyChecked.length > 1
                              ?  `${companyChecked.length} empresas selecionadas`
                              : `${companyChecked.length} empresa selecionada`
                            : 'Selecione a Empresa'
                        }
                      </Flex>
                    </Link>
                  </Flex>

                  <SelectCompanies
                    guid_client={props.guid_client}
                    companies={allCompanies}
                    open={openSelectCompanies}
                    setOpen={setOpenSelectCompanies}
                    selectedCompanies={companyChecked}
                    setSelectedCompanies={setCompanyChecked}
                    header="Selecionar Empresas"
                    description=""
                    buttonText="Salvar alteração"
                    isLoading={false}
                  />
                </Flex>
              </Flex>

              <Flex mt="15px" flexDirection="column">
                <Text fontSize={16} mb="15px" fontWeight={600}>Definir Regras de Agendamento Automático</Text>

                <ScheduleRules
                  guid_client={props.guid_client}
                  obligations={darfsSelecteds}
                  setObligations={setDarfsSelecteds}
                  isLoading={obligationIsLoading}
                />
              </Flex>

              {darfsSelecteds && darfsSelecteds.data && darfsSelecteds.data.filter((e) => e.dueDate === 'specifydate').length > 0 && (
                <Alert status="warning" justifyContent="space-between" gap="200px">
                  <Flex alignItems="center">
                    <AlertIcon />
                    Uma ou mais obrigações possuem dias específicos. Selecione como o sistema se comportará quando no próximo agendamento o dia cair como feriado ou final de semana.
                  </Flex>
                  <Flex>
                    <Select size="xs" fontSize={12} borderColor="gray.500" value={fixedRule} onChange={(e) => setFixedRule(e.target.value)} w={150} bg="white">
                      <option value="toAntecipate">Antecipar</option>
                      <option value="postpone">Adiar</option>
                    </Select>
                  </Flex>
                </Alert>
              )}

              
            </ModalBody>

            <ModalFooter borderTop={'1px solid #CBD5E0'}>
              <Button
                bgColor="white"
                color="#171923"
                _hover={{ bg: 'white', textDecoration: 'underline' }}
                onClick={() => setCurrentStep(3)}
                mr={5}
              >
                Voltar
              </Button>

              <Button
                bgColor="#4B4EFF"
                color="white"
                width={104}
                height={37}
                _hover={{ bg: '#282be0' }}
                onClick={createSchedule}
              >
                Criar tarefa
              </Button>
            </ModalFooter>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
