export enum EEntityConfig {
    NONE = 'NONE',
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
    ALL = 'ALL',
}

export enum EEntityType {
    COMPANY = 'COMPANY',
    PERSONA = 'PERSONA',
}  

export interface ICertificate {
    guid_certificate: string;
    guid_parent?: string;
    jurisdiction?: String;
    allow_states?: String;
    allow_cities?: String;
    slug: string;
    name: String;
    recurrency?: IRecurrency[];
    status?: IStatus[];
    databroker_name: String;
    time_spent_issuing?: String;
    send_weekly_update_mail?: String;
    origin_link?: String;
    custom_buttons?: String;
    custom_columns?: String;
    config_company: EEntityConfig;
    config_persona: EEntityConfig;
    hasSimilarChildren: boolean;
    children?: ICertificate[];
}

interface IRecurrency {
    name: String;
    value: String;
    cron: String;
    only_working_day: boolean;
}

export interface IStatus {
    name: string;
    isIssued: boolean
    slug: string;
    show_file: boolean;
    color: string;
    textColor?: string;
    tooltip_icon?: string;
    tooltip_text?: string;
    can_show_issued_date: boolean;
}

export interface ICertParams {
    cities: {[key: string]: string[]};
    states: {[key: string]: string[]};
}

export interface ICertificateParamsForLocation {
    mandatory: ICertParams;
    editable: ICertParams;
}