import { ChevronDownIcon } from "@chakra-ui/icons";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button, Link, Flex, Textarea, InputGroup, Select, Menu, MenuButton, Box, Image, useToast, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from "react";
import { TaxlyInput } from '../../../../../components/Input';
import { SelectCollaboratorModal } from "../../../Users/modais/SelectCollaboratorModal";
import { FilterDataSelected, Filters, FilterTypeEnum } from "../../../../../components/Filters";
import { getClientCompanies } from "../../../../../_services/company.service";
import { IClientCompanies, IClientCompaniesSchema } from "../../../../../_services/interface/company.interface";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../components/Table/table.interface";
import { formatCnpj } from "../../../Companies/Company/util/cnpj";
import { stateRegionForTag } from "../../../../../components/Table/utils";
import { Datatables } from "../../../../../components/Table/Datatables";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { DeleteKanbanCardModal } from "../DeleteCardModal";
import { CommentPanel } from "./Comments/CommentPanel";
import { ECompanyStatus, EPriority, EFrequencyType } from "../../../../../_services/enum/irs.enum";
import moment from "moment";
import { editCard, getCard } from "../../../../../_services/irs.service";
import { ICard, ICardStructureCertificates, ICardStructureCompanies, ICardStructureHistory, ICNDListDataForCard, ICreateCardCompany } from "../../../../../_services/interface/irs.interface";
import { deleteCard, patchEditCard } from '../../_services/kanban.service'
import { SaveEditTask } from "./SaveEditTask";
import DatePickerWithOptions from "./DatePickerWithOptions";
import { SelectCompanies } from "../../../../../modal/SelectCompanies";
import { ModalConfirm } from "../../../../../modal/ModalConfirm";
import { CompanyAttachment } from "../../components/CompanyAttachment"
import { Tag } from '../../../../../components/Table/Tag'
import { filterRowsCertificates } from "./Util/FiltersAndColumns";
import { ICertificate } from "../../../../../_services/interface/certificate.interface";
import { getClientCertificates } from "../../../../../_services/certificate.service";
import { getValidUntilDates, statusForTagList } from "../../../CNDManager/util/cndUtils";
import { ICNDListData } from "../../../../../_services/interface/cnd.interface";
import { ICNDListDataSchema } from "./Interface/IAddNewTaskForm";
import { SelectCertificates } from "../../../../../modal/SelectCertificates";
import { IEditTaskProps, IEditTaskForm, IOption } from "./Interface/IEditTask";
import { priorityOptions, optionsLote, optionsLoteObligation, getTag, filterRows } from "./Util/UEditTask";
import SelectWithIcon from '../../../../../components/SelectWithIcon'
import {
  CertificateHistory
} from '../../../CNDManager/modals/CertificateHistory'

export const EditTask = (props: IEditTaskProps) => {
  const toast = useToast();
  const { isOpen, onClose } = props;
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [commentPanelReduced, setCommentPanelReduced] = useState<boolean>(false);

  const [disabledButtonSave, setDisabledButtonSave] = useState<boolean>(false);

  // Card Data
  const [card, setCard] = useState<ICard>();
  const [history, setHistory] = useState<ICardStructureHistory[]>([]);
  const [cardCompanies, setCardCompanies] = useState<string[]>([]);
  const [ cardDefeated, setCardDefeated ] = useState<boolean>(false)
  const [ validateCardStatus, setValidadeCardStatus ] = useState<boolean>(false)

  // Collaborator
  const [openSelectCollaborator, setOpenSelectCollaborator] = useState<boolean>(false);
  const [selectedsCollaborator, setSelectedsCollaborator] = useState<string[]>([]);

  // Certificate
  const [certificatesUse, setCertificatesUse] = useState<ICertificate[]>([]);
  const [certificateMetaData, setCertificateMetaData] = useState<ITableMetaData|undefined>();
  const [certificates, setCertificates] = useState<ICNDListDataForCard[]>([]);
  const [certificateList, setCertificateList] = useState<IData|null>(null);
  const [certificateListAll, setCertificateListAll] = useState<IData|null>(null);
  const [reinitFilter, setReinitFilter] = useState<boolean>(false);
  const [selectedCertificates, setSelectedCertificates] = useState<ICNDListDataForCard[]>([]);
  const [certificatesChecked, setCertificatesChecked] = useState<string[]>([]);
  const [excludeCertificatesGuid, setExcludeCertificatesGuid] = useState<string[]>([]);
  const [listCertificatesAlreadyLoaded, setListCertificatesAlreadyLoaded] = useState<boolean>(false);
  const timeoutListCertificateRef = useRef<NodeJS.Timeout | null>(null);

  // Company Data
  const [loadingCompanyData, setLoadingCompanyData] = useState<boolean>(false);
  const [filteredCompanies, setFilteredCompanies] = useState<IData|null>(null);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();
  const [companies, setCompanies] = useState<IData|null>(null);
  const [companyAll, setCompanyAll] = useState<IData|null>(null);
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [checkedCompanies, setCheckedCompanies] = useState<string[]>([]);
  const [listCompanyAlreadyLoaded, setListCompanyAlreadyLoaded] = useState<boolean>(false);
  const timeoutListCompanyRef = useRef<NodeJS.Timeout | null>(null);

  const [isOpenModalSave, setIsOpenModalSave] = useState<boolean>(false);
  const [saveForAllCards, setSaveForAllCards] = useState<string>("0");
  const [selectedLoteOption, setSelectedLoteOption] = useState<ECompanyStatus>(ECompanyStatus.PENDENCY);

  const [dueDate, setDueDate] = useState<Date|undefined>();
  const [frequencyType, setFrequencyType] = useState<EFrequencyType|null>(null);
  const [frequencyUnit, setFrequencyUnit] = useState<number|null>(null);
  const [frequency, setFrequency] = useState<string|null>(null);
  const [jurisdiction, setJurisdiction] = useState<string|null>(null);

  const [isOpenRemoveCompanies, setIsOpenRemoveCompanies] = useState<boolean>(false);
  const [isOpenRemoveCertificates, setIsOpenRemoveCertificates] = useState<boolean>(false);

  const [selectedOption, setSelectedOption] = useState(priorityOptions[0]);
  const [status, setStatus] = useState<IOption>();

  const [currentCertificate, setCurrentCertificate] = useState<ICNDListDataForCard | null>(null);
  const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);

  const YupCompanyChangeForm = Yup.object().shape({
    companies: card && card.companies && card.companies.length > 0 ? Yup.array().of(IClientCompaniesSchema).min(1, "Selecione ao menos 1 empresa").required("Empresas são obrigatórias") : Yup.array().of(IClientCompaniesSchema).nullable(),
    certificates: card && card.certificates && card.certificates.length > 0 ? Yup.array().min(1, "Selecione ao menos 1 certidão").required("Certidões são obrigatórias") : Yup.array().nullable(),
    collaborators: Yup.array().of(Yup.string()).min(1, "Selecione ao menos 1 responsável").required("Colaboradores são obrigatórios"),
    priority: Yup.string().required("Prioridade é obrigatória"),
    status: Yup.string().required("Status é obrigatório"),
    name: Yup.string().required("Nome é obrigatório"),
    description: Yup.string().nullable(),
    frequencyType: Yup.string().nullable(),
    frequencyUnit: Yup.number().nullable(),
    frequency: Yup.string().nullable(),
    dueDate: Yup.date().required("Data de vencimento é obrigatória"),
    calculationPeriod: Yup.string().nullable(),
    jurisdiction: Yup.string().nullable(),
    fine: Yup.number().nullable(),
  });

  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };

  const {
    formState:
      { errors },
      register,
      getValues,
      setValue,
      reset,
      handleSubmit
  } = useForm<IEditTaskForm>(resolverForm);

  let metaAll: IDataMeta = {
    currentPage: 1,
    isFirstPage: true,
    isLastPage: true,
    nextPage: null,
    pageCount: 1,
    previousPage: null,
    totalCount: certificates.length
  };

  const fetchCompany = async () => {
    if (props.guid_client && card) {
      setLoadingCompanyData(true);

      const { status, response } = await getClientCompanies(props.guid_client);

      if (status === 200) {
        if (response !== undefined) {
          const companies = response as IClientCompanies[];

          setAllCompanies(companies);
        }
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }

      setTimeout(() => {
        setLoadingCompanyData(false);
      }, 1000);
    }
  }

  const fetchCertificates = async () => {
    const { status, response } = await getClientCertificates(props.guid_client);
    if (status === 200) {
      setCertificatesUse(response as ICertificate[]);
    }
  }

  const sortCompanies = (
    companyMetaData: ITableMetaData,
    companies: IClientCompanies[],
    companyChecked: string[]
  ): IClientCompanies[] => {
    // Mover os registros dentro de companyChecked para o início do array
    const checkedCompanies = companies.filter(company => companyChecked.includes(company.guid_company));
    const uncheckedCompanies = companies.filter(company => !companyChecked.includes(company.guid_company));

    const orderField = companyMetaData.orderField as keyof IClientCompanies;
    const orderDirection = companyMetaData.orderDirection;

    const compareFunction = (a: IClientCompanies, b: IClientCompanies): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];

      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }

      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };

    // Ordenar os uncheckedCompanies de acordo com a orderField e orderDirection
    uncheckedCompanies.sort(compareFunction);
    checkedCompanies.sort(compareFunction);

    // Combinar as listas checkedCompanies e sorted uncheckedCompanies
    const sortedData = [...checkedCompanies, ...uncheckedCompanies];

    return sortedData;
  };

  const filterCompanies = (orderedCompanies: IClientCompanies[], filterDataSelected: FilterDataSelected): IClientCompanies[] => {
    let orderedCompaniesForFilter = orderedCompanies;

    if (orderedCompanies.length > 0 && companies && companies.data) {
      if(filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
        orderedCompaniesForFilter = orderedCompanies.filter((company) => filterDataSelected[FilterTypeEnum.companyFilter].includes(company.guid_company));
      }

      if(filterDataSelected[FilterTypeEnum.companyGroupFilter].length > 0) {
        orderedCompaniesForFilter = orderedCompanies.filter((company) => company.groups.some((r) => filterDataSelected[FilterTypeEnum.companyGroupFilter].includes(r.guid_company_group)));
      }

      // Criando uma lista de empresas filtradas
      let filteredCompanies = orderedCompaniesForFilter;

      // Adicionando empresas selecionadas à lista filtrada
      filteredCompanies = filteredCompanies.concat(companies.data.filter(company => companyChecked.includes(company.guid_company)));

      // Removendo duplicatas (se houver)
      filteredCompanies = Array.from(new Set(filteredCompanies));

      return filteredCompanies;
    }

    return [];
  }

  const selectAll = () => {
    if(filteredCompanies) {
      if (companyChecked.length === filteredCompanies.data.filter((company) => company.isActive).length && companyChecked.length > 0) {
        setCompanyChecked([]);
      } else {
        setCompanyChecked(filteredCompanies.data.filter((company) => company.isActive).map((x): string => x.guid_company));
      }
    }
  }

  const selectAllCertificates = () => {
    if(certificateList) {
      if (certificatesChecked.length === certificateList.data.length && certificatesChecked.length > 0) {
        setCertificatesChecked([]);
      } else {
        setCertificatesChecked(certificateList.data.map((certificate): string => certificate.guid_cnd));
      }
    }
  }

  const handleFormInfo = async (data: IEditTaskForm) => {
    setDisabledButtonSave(true);
    if(!card || (!companies || !companies.data)) {
      setDisabledButtonSave(false);
      return;
    }
    const formattedDueDate = moment(data.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD');

    let responseStatus = null;
    if (card.guid_card_structure) {
      let companiesToSend: ICreateCardCompany[] = [];

      if (data.companies?.length > 0 && companyAll?.data) {
        companiesToSend = companyAll.data.map((company) => ({
          guid_company: company.guid_company,
          certificateSlug: null,
          certificateType: null,
          certificateState: null,
          certificateCity: null,
          guidCnd: null,
          status: company.status as ECompanyStatus,
        }));
      } else if (data.certificates?.length > 0 && certificateListAll?.data) {
        companiesToSend = certificateListAll.data.map((certificate) => {
          let data = {
            guid_company: certificate.guid_company,
            certificateSlug: certificate.isManual ? null : certificate.slug,
            certificateType: certificate.slug.split('_')[0],
            certificateState: certificate.state ? certificate.state.initials : null,
            certificateCity: certificate.city ? certificate.city.name : null,
            guidCnd: certificate.guid_cnd,
            status: certificate.status_card,
          };

          if (card.certificates) {
            const oldCert = card.certificates.find((cert) => cert.certificate.guid_cnd === certificate.guid_cnd);
        
            if (oldCert) {
              const oldData: ICreateCardCompany = {
                guid_company: certificate.guid_company,
                certificateSlug: oldCert.certificate_slug,
                certificateType: oldCert.certificate_type,
                certificateState: oldCert.certificate_state,
                certificateCity: oldCert.certificate_city,
                guidCnd: certificate.guid_cnd,
                status: certificate.status_card,
              };
              return oldData;
            }
          }

          return data;
        });
      }

      const frequencyTypeToSend = frequencyType ? (frequencyType as EFrequencyType) : undefined;
      const frequencyUnitToSend = frequencyUnit ? frequencyUnit : undefined;

      let updateRecurrency: boolean = false;
      if(
        (
          frequencyTypeToSend !== card.cardStructure?.schedule.frequencyType
          || frequencyUnitToSend !== card.cardStructure?.schedule.frequencyUnit
        ) 
        || (
          card.cardStructure?.hasRecurrency === false
          && frequencyTypeToSend 
          && frequencyUnitToSend
        )
      ) {
        updateRecurrency = true;
      }

      const { status } = await editCard(
        props.guid_client,
        card.guid_card_structure,
        props.guid_card,
        {
          name: data.name,
          description: data.description,
          priority: data.priority as EPriority,
          companies: companiesToSend,
          responsibles: data.collaborators,
          dueDate: formattedDueDate,
          status: data.status,
          updateAll: saveForAllCards === '1' ? true : false,
          updateRecurrency: updateRecurrency,
          hasRecurrency: frequencyTypeToSend && frequencyUnitToSend ? true : false,
          frequencyType: frequencyTypeToSend,
          frequencyUnit: frequencyUnitToSend,
          cardStructureType: data.certificates && data.certificates.length > 0 ? `CERTIFICATE_COMPANY` : `COMPANY`,
        }
      );

      responseStatus = status;
    } else {
      const sanitizedCompanies: any[] = [];
      companies.data.forEach((e) => {
        sanitizedCompanies.push({
          guid_cardCompany: e.companyCard.guid_cardCompany,
          status: e.status,
        });
      })

      const { status } = await patchEditCard(props.guid_client, props.guid_card, {
        name: data.name,
        jurisdiction: jurisdiction,
        description: data.description !== "" ? data.description : undefined,
        dueDate: moment.utc(data.dueDate, 'DD/MM/YYYY').format(),
        frequency: frequency,
        fine: data.fine ? data.fine : undefined,
        companies: sanitizedCompanies,
        responsibles: data.collaborators as string[],
        calculationPeriod: data.calculationPeriod ? data.calculationPeriod : undefined,
      });

      responseStatus = status;
    }

    if(responseStatus === 200) {
      props.onClose();
      props.flushHook(true);

      toast({
        title: 'Tarefa alterada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar editar a tarefa',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }

    setDisabledButtonSave(false);
  }

  const removeCard = async () => {
    const { status } = await deleteCard(props.guid_client, props.guid_card);
    if (status === 200) {
      toast({
        title: 'Card deletado',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  }

  const updateAllStatusCompany = () => {
    if(companies && companyAll) {
      const updateCompanies = companies.data.map((company) => {
        if(companyChecked.includes(company.guid_company)) {
          return {
            ...company,
            status: selectedLoteOption
          };
        }

        return company;
      });

      setCompanies({
        data: updateCompanies,
        meta: companies.meta
      });

      const updateCompaniesAll = companyAll.data.map((company) => {
        if(companyChecked.includes(company.guid_company)) {
          return {
            ...company,
            status: selectedLoteOption
          };
        }

        return company;
      });

      setCompanyAll({
        data: updateCompaniesAll,
        meta: companyAll.meta
      });
    }

    if (certificateList && certificateListAll) {
      const updateCertificatesList = certificateList.data.map((certificate) => {
        if(certificatesChecked.includes(certificate.guid_cnd)) {
          return {
            ...certificate,
            status_card: selectedLoteOption
          };
        }

        return certificate;
      });

      setCertificateList({
        data: updateCertificatesList,
        meta: certificateList.meta
      });

      const updateCertificatesListAll = certificateListAll.data.map((certificate) => {
        if(certificatesChecked.includes(certificate.guid_cnd)) {
          return {
            ...certificate,
            status_card: selectedLoteOption
          };
        }

        return certificate;
      });

      setCertificateListAll({
        data: updateCertificatesListAll,
        meta: certificateListAll.meta
      });
    }

    setValidadeCardStatus(true);
  }

  const fetchHistory = async () => {
    const { status, response } = await getCard(props.guid_client, props.guid_card);
    if (status === 200 && response) {
      const responseCard = response as ICard;
      if(responseCard.history) {
        setHistory(responseCard.history);
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setReinitFilter(false);
    }, 1000);
  }, [reinitFilter])

  const orderCertificate = (
    certificates: ICNDListDataForCard[],
    orderField: keyof ICNDListDataForCard,
    orderDirection: 'asc' | 'desc'
  ): ICNDListDataForCard[] => {
    const compareFunction = (a: ICNDListDataForCard, b: ICNDListDataForCard): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined || aValue === null || bValue === null) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    return certificates.sort(compareFunction);
  }

  const filterCertificate = (certificates: ICNDListDataForCard[]): ICNDListDataForCard[] => {
    let certificatesForFilter = certificates;

    if(!filterDataSelected) {
      return certificates;
    }

    if (certificatesForFilter.length > 0) {
      if(filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
        const companyFilter = filterDataSelected[FilterTypeEnum.companyFilter];

        certificatesForFilter = certificatesForFilter.filter((certificate) => certificate.guid_company && companyFilter.includes(certificate.guid_company));
      }

      if(filterDataSelected[FilterTypeEnum.companyTypeFilter].length === 1) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return certificate.entity.type === Number(filterDataSelected[FilterTypeEnum.companyTypeFilter][0])
        });
      }

      if(filterDataSelected[FilterTypeEnum.certificateStateFilter].length > 0) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return certificate.state && filterDataSelected[FilterTypeEnum.certificateStateFilter].includes(certificate.state.initials)
        });
      }

      if(filterDataSelected[FilterTypeEnum.certificateCityFilter].length > 0) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return certificate.city && filterDataSelected[FilterTypeEnum.certificateCityFilter].includes(certificate.city.name)
        });
      }


      if(filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return certificate.entity.code && filterDataSelected[FilterTypeEnum.companyCodeFilter].includes(certificate.entity.code)
        });
      }

      if(filterDataSelected[FilterTypeEnum.certificateTypeFilter].length > 0) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return filterDataSelected[FilterTypeEnum.certificateTypeFilter].includes(certificate.slug.split('_')[0])
        });
      }

      if(filterDataSelected[FilterTypeEnum.certificateStatusFilter].length > 0) {
        const statuses = filterDataSelected[FilterTypeEnum.certificateStatusFilter];
        const statusValues: string[] = [];

        statuses.forEach((status) => {
          const statusSplited = status.split('_');
          const stat = statusSplited[statusSplited.length - 1];
  
          if (!statusValues.some((s) => s === stat)) {
            statusValues.push(stat);
          }
        });

        certificatesForFilter = certificatesForFilter.filter((certificate) => { return statusValues.includes(certificate.slug_status) });
      }

      if (filterDataSelected[FilterTypeEnum.validUntilFilter].length > 0) {
        const {startDueDate, endDueDate} = getValidUntilDates(filterDataSelected[FilterTypeEnum.validUntilFilter]);
  
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          if(certificate.validUntil === null) {
            return false;
          }
          const validUntilDate = moment.utc(certificate.validUntil, 'YYYY-MM-DD');
          const isAfterStart = startDueDate ? validUntilDate.isSameOrAfter(startDueDate) : true;
          const isBeforeEnd = endDueDate ? validUntilDate.isSameOrBefore(endDueDate) : true;
          return isAfterStart && isBeforeEnd;
        });
      }

      if(filterDataSelected[FilterTypeEnum.searchTypeFilter].length === 1) {
        if(filterDataSelected[FilterTypeEnum.searchTypeFilter][0] === '1') {
          certificatesForFilter = certificatesForFilter.filter((certificate) => {
            return certificate.isManual
          });
        }

        if(filterDataSelected[FilterTypeEnum.searchTypeFilter][0] === '0') {
          certificatesForFilter = certificatesForFilter.filter((certificate) => {
            return !certificate.isManual
          });
        }
      }

      if(filterDataSelected[FilterTypeEnum.jurisdictionFilter].length > 0) {
        certificatesForFilter = certificatesForFilter.filter((certificate) => {
          return filterDataSelected[FilterTypeEnum.jurisdictionFilter].includes(certificate.jurisdiction)
        });
      }

      return certificatesForFilter;
    }

    return [];
  }
  
  const updateListCertificate = () => {
    let certificatesForList = filterCertificate(certificates);

    const rowByPage = certificateMetaData?.rowByPage ?? 10;
    const currentPage = certificateMetaData?.currentPage ?? 1;
    const totalPages = Math.ceil(certificatesForList.length / rowByPage);
  
    let meta: IDataMeta = {
      currentPage: currentPage,
      isFirstPage: currentPage === 1,
      isLastPage: currentPage === totalPages,
      nextPage: currentPage === totalPages ? null : currentPage + 1,
      pageCount: totalPages,
      previousPage: currentPage === 1 ? null : currentPage - 1,
      totalCount: certificatesForList.length
    };
  
    const orderField: keyof ICNDListDataForCard = certificateMetaData?.orderField as keyof ICNDListDataForCard ?? 'guid_cnd' as keyof ICNDListDataForCard;
    const orderDirection: 'asc' | 'desc' = certificateMetaData?.orderDirection as 'asc' | 'desc' ?? 'desc';

    certificatesForList = orderCertificate(certificatesForList, orderField, orderDirection);
  
    const startIndex = (currentPage - 1) * rowByPage;
    const endIndex = startIndex + rowByPage;
    certificatesForList = certificatesForList.slice(startIndex, endIndex === -1 ? certificatesForList.length : endIndex);
  
    const certificateForCard = certificatesForList.map((row) => {
      let certificateInList: any = null;
      if (certificateListAll && certificateListAll.data) {
        certificateInList = certificateListAll.data.find((certificate) => certificate.guid_cnd === row.guid_cnd);
      }

      return {
        ...row,
        status_card: certificateInList ? certificateInList.status_card : row.status_card,
      };
    });
  
    setCertificateList({
      data: certificateForCard,
      meta: meta
    });
  }

  useEffect(() => {
    updateListCertificate();
  }, [certificates, certificateMetaData, filterDataSelected])

  useEffect(() => {
    if(selectedCertificates.length > 0) {
      const selectedCertificatesToAdd = selectedCertificates
        .map((certificate) => {
          return {
            ...certificate,
            status_card: ECompanyStatus.PENDENCY,
            isNewStatus: false,
          };
        })
        .filter((certificate) => {
          return !certificates.some(
            (cert) =>
              cert.guid_cnd === certificate.guid_cnd ||
              (cert.guid_company === certificate.guid_company &&
                cert.slug.split("_")[0] === certificate.slug.split("_")[0] &&
                cert.state === certificate.state &&
                cert.city === certificate.city)
          );
        });

      const newCertificates = [...certificates, ...selectedCertificatesToAdd];
      const newCertificatesAll = [...certificateListAll?.data!, ...selectedCertificatesToAdd];
      setCertificates(newCertificates);
      setCertificateListAll({ data: newCertificatesAll, meta: metaAll });
    }
  }, [selectedCertificates]);

  const fetchCard = async () => {
    const { status, response } = await getCard(props.guid_client, props.guid_card);
    if (status === 200 && response) {
      setCardDefeated(false);

      const responseCard = response as ICard;
      setCard(responseCard);

      if(
          responseCard.cardStructure?.hasRecurrency 
          && responseCard.cardStructure?.schedule.frequencyType 
          && responseCard.cardStructure?.schedule.frequencyUnit
      ) {
        setFrequencyType(responseCard.cardStructure.schedule.frequencyType as EFrequencyType);
        setFrequencyUnit(responseCard.cardStructure.schedule.frequencyUnit);
      }

      if(responseCard.history) {
        setHistory(responseCard.history);
      }

      if(responseCard.certificates) {
        const certificateCard = responseCard.certificates.map((certificate) => {
          return {
            ...certificate.certificate,
            status_card: certificate.status,
            isNewStatus: certificate.isNewStatus
          }
        });

        setCertificates(certificateCard)
        fetchCertificates();
        setReinitFilter(true);
      }

      const companiesGuids = responseCard.companies && responseCard.companies.length > 0 ? responseCard.companies.map((comp) => comp.company.guid_company) : [];
      setCardCompanies(companiesGuids);

      const collaboratorGuids = responseCard.responsibles.map((responsible) => responsible.collaborator.guid_collaborator);
      setSelectedsCollaborator(collaboratorGuids);
      const priority = priorityOptions.find((priority) => priority.value === responseCard.priority);

      if(priority) {
        setSelectedOption(priority);
      }

      let optionsStatus = optionsLote;

      if(!responseCard.cardStructure) {
        optionsStatus = optionsLoteObligation;
      }

      let cardStatusOption = optionsStatus.find((option) => option.value === responseCard.status);
      if(!cardStatusOption) {
        cardStatusOption = optionsStatus[0];
      }

      setStatus(cardStatusOption);

      if (responseCard.obligation?.frequency) {
        setFrequency(responseCard.obligation.frequency);
      }

      if (responseCard?.obligation?.jurisdiction) {
        setJurisdiction(responseCard.obligation.jurisdiction);
      }

      const formattedDueDate = moment(responseCard.dueDate).utc().add(3, 'hours').toDate();
      setDueDate(formattedDueDate);

      reset({
        name: responseCard.name,
        description: responseCard.description ?? undefined,
        collaborators: collaboratorGuids,
        priority: responseCard.priority,
        status: cardStatusOption.value,
      });
    }
  }

  const removeCompanies = () => {
    const updatedCardCompanies = cardCompanies.filter(company => !companyChecked.includes(company));
    setCardCompanies(updatedCardCompanies);

    const updatedSelectedCompanies = selectedCompanies.filter(company => !companyChecked.includes(company));
    setSelectedCompanies(updatedSelectedCompanies);

    setCompanyChecked([]);
  }

  const removeCertificates = () => {
    const newCertificates = certificates.filter((certificate) => !certificatesChecked.includes(certificate.guid_cnd));
    const newCertificatesListAll = certificateListAll?.data?.filter((certificate) => !certificatesChecked.includes(certificate.guid_cnd));
    setCertificates(newCertificates);
    setCertificateListAll({ data: newCertificatesListAll!, meta: metaAll });
    setCertificatesChecked([]);
  }

  const validateConfirmationModal = async () => {
    const formData = getValues();

    function validateEquityCertificates(
      cardCertificates: ICardStructureCertificates[],
      formCertificates: ICNDListDataForCard[]
    ): boolean {
      if (cardCertificates.length !== formCertificates.length) {
        return false;
      }

      for (let i = 0; i < cardCertificates.length; i++) {
        const formFindCertificate = formCertificates.find(
          (certificate) => certificate.guid_company === cardCertificates[i].certificate.guid_company &&
              cardCertificates[i].certificate_slug ? (certificate.slug === cardCertificates[i].certificate_slug) : certificate.isManual &&
              certificate.slug.split('_')[0] === cardCertificates[i].certificate_type &&
              cardCertificates[i].certificate_state ? (certificate.state === cardCertificates[i].certificate_state) : true &&
              cardCertificates[i].certificate_city ? (certificate.city === cardCertificates[i].certificate_city) : true
        );

        if (formFindCertificate === undefined) {
          return false;
        }
      }

      return true;
    }

    function validateEquityCompany(
      cardCompanies: ICardStructureCompanies[],
      formCompanies: IClientCompanies[]
    ): boolean {
      if (cardCompanies.length !== formCompanies.length) {
        return false;
      }

      for (let i = 0; i < cardCompanies.length; i++) {
        const formFindCompany = formCompanies.find(
          (company) => company.guid_company === cardCompanies[i].company.guid_company
        );

        if (formFindCompany === undefined) {
          return false;
        }
      }

      return true;
    }

    if(card) {
      if (
        card?.cardStructure?.hasRecurrency &&
        (formData.name !== card.name ||
        formData.description !== card.description ||
        formData.priority !== card.priority ||
        !moment(formData.dueDate).isSame(moment(card.dueDate).add(3, 'hours'), 'days') ||
        !(card.responsibles.every((responsible) =>
          formData.collaborators.includes(
            responsible.collaborator.guid_collaborator
          )
        ) && formData.collaborators.length === card.responsibles.length) ||
        !validateEquityCertificates(card.certificates ? card.certificates : [], formData.certificates ? formData.certificates : []) ||
        !validateEquityCompany(card.companies ? card.companies : [], formData.companies ? formData.companies : []))
      ) {
        setIsOpenModalSave(true); 
      } else {
        handleSubmit(handleFormInfo)();
      };
    }
  }

  const changeCardStatus = () => {
    if (
      (companyAll &&
        companyAll.data.length > 0 &&
        companyAll.data.every((company) => {
          return company.status === ECompanyStatus.DONE;
        })) ||
      (certificateListAll &&
        certificateListAll.data.length > 0 &&
        certificateListAll.data.every((certificate) => {
          return certificate.status_card === ECompanyStatus.DONE;
        }))
    ) {
      setStatus({ value: ECompanyStatus.DONE, label: "Finalizado" } as IOption);
    }
  };

  useEffect(() => {
    if (dueDate && moment(dueDate).isBefore(moment(), 'day')) {
      setCardDefeated(true);
    }
  }, [dueDate]);

  useEffect(() => {
    if(status) {
      setValue('status', status.value);
    }
  }, [status]);

  useEffect(() => {
    if(card) {
      fetchCompany();
    }
  }, [card])

  useEffect(() => {
    if(certificates) {
      setExcludeCertificatesGuid(certificates.map((cert) => cert.guid_cnd));
    }
  }, [certificates])

  useEffect(() => {
    if(cardCompanies && allCompanies && card) {
      const companiesInUse = allCompanies.filter((company) => cardCompanies.includes(company.guid_company) || selectedCompanies.includes(company.guid_company));

      let meta: IDataMeta;
      if(companyMetaData) {
        const { currentPage, totalPages, rowByPage } = companyMetaData;

        meta = {
          currentPage,
          isFirstPage: currentPage === 1,
          isLastPage: currentPage === totalPages,
          nextPage: currentPage === totalPages ? null : currentPage + 1,
          pageCount: Math.ceil(companiesInUse.length / rowByPage),
          previousPage: currentPage === 1 ? null : currentPage - 1,
          totalCount: companiesInUse.length
        };
      } else {
        const totalPages = Math.ceil(companiesInUse.length / 10);

        meta = {
          currentPage: 1,
          isFirstPage: true,
          isLastPage: 1 === totalPages,
          nextPage: 1 === totalPages ? null : 1 + 1,
          pageCount: totalPages,
          previousPage: 1 === 1 ? null : 1 - 1,
          totalCount: companiesInUse.length
        };
      }

      const companiesForCard = companiesInUse.map((company) => {
        let companyCard = card.companies?.find((companyFilter) => {
          return companyFilter.company.guid_company === company.guid_company;
        });

        if (companyCard) {
          companyCard.company['guid_cardCompany'] = companyCard.guid_cardCompany;
        }

        return {
          ...company,
          status: companyCard ? companyCard.status : ECompanyStatus.PENDENCY,
          companyCard: companyCard,
        }
      });

      setCompanies({
        data: companiesForCard,
        meta: meta
      });
    }
  }, [allCompanies, selectedCompanies, cardCompanies])

  useEffect(() => {
    if (checkedCompanies.length > 0) {
      setSelectedCompanies(prevList => {
        const newSet = new Set([...prevList, ...checkedCompanies]);
        return Array.from(newSet);
      });

      setCheckedCompanies([]);
    }
  }, [checkedCompanies])

  useEffect(() => {
    if(allCompanies && card) {
      let guids: string[] = [];
      if(card.companies) {
        const companiesCard = allCompanies.filter((company) => card.companies?.some((comp) => comp.company.guid_company === company.guid_company));
        guids = companiesCard.map((company) => company.guid_company);
      }

      if(card.certificates) {
        const companiesCard = allCompanies.filter((company) => card.companies?.some((comp) => comp.company.guid_company === company.guid_company));
        guids = companiesCard.map((company) => company.guid_company);
      }
      
      setSelectedCompanies(guids);
    }
  }, [allCompanies])

  useEffect(() => {
    if (companyMetaData && companies && companies.data && filterDataSelected) {
      const filteredCompanies = filterCompanies(companies.data, filterDataSelected);
      const orderedCompanies = sortCompanies(companyMetaData, filteredCompanies, companyChecked);

      const { currentPage, totalPages, rowByPage } = companyMetaData;
      
      const data = orderedCompanies;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end === -1 ? data.length : end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCompanies.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCompanies.length
      }

      setFilteredCompanies({
        data: paginatedData,
        meta
      });
    }
  }, [companyMetaData, companies, filterDataSelected]);

  useEffect(() => {
    setValue('priority', selectedOption.value);
  }, [selectedOption]);

  useEffect(() => {
    if(certificateList && certificateList.data) {
      const formCerts: any[] = [];
      const certificatesToValidate = certificateListAll && certificateListAll.data.length ? certificateListAll.data : certificates;

      const certs = certificatesToValidate.map((cert) => {
        const certificateListFind = certificateList.data.find((c) => c.guid_cnd === cert.guid_cnd);
        if (certificateListFind) {
          const certWithStatus = {
            ...cert,
            status_card: certificateListFind.status_card
          };

          if (cert.status_card !== certWithStatus.status_card) {
            setValidadeCardStatus(true);
          }
          
          formCerts.push(certWithStatus);
          return certWithStatus;
        }
        
        return cert;
      });

      setCertificateListAll({data: certs, meta: metaAll});
      setValue('certificates', formCerts);

      if (timeoutListCertificateRef.current) {
        clearTimeout(timeoutListCertificateRef.current);
      }

      timeoutListCertificateRef.current = setTimeout(() => {
        setListCertificatesAlreadyLoaded(true);
      }, 2000);
    }
  }, [certificateList]);

  useEffect(() => {
    if(filteredCompanies && filteredCompanies.data && companies && companies.data) {
      const companiesToValidate = companyAll && companyAll.data.length ? companyAll.data : companies.data;
      const formCompanies: any[] = [];

      const companiesToSet = companiesToValidate.map((comp) => {
        const companyFind = filteredCompanies.data.find((c) => c.guid_company === comp.guid_company);
        if (companyFind) {
          const companyWithStatus = {
            ...comp,
            status: companyFind.status
          };

          if (comp.status !== companyWithStatus.status) {
            setValidadeCardStatus(true);
          }

          formCompanies.push(companyWithStatus);
          return companyWithStatus;
        }
        return comp;
      });

      let meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: true,
        nextPage: null,
        pageCount: 1,
        previousPage: null,
        totalCount: companiesToSet.length
      };
      setCompanyAll({data: companiesToSet, meta: meta});
      setValue('companies', formCompanies);

      if (timeoutListCompanyRef.current) {
        clearTimeout(timeoutListCompanyRef.current);
      }

      timeoutListCompanyRef.current = setTimeout(() => {
        setListCompanyAlreadyLoaded(true);
      }, 2000);
    }
  }, [filteredCompanies]);

  useEffect(() => {
    if (validateCardStatus) {
      changeCardStatus();
      setValidadeCardStatus(false);
    }
  }, [certificateListAll, companyAll]);

  useEffect(() => {
    setValue('collaborators', selectedsCollaborator);
  }, [selectedsCollaborator]);

  useEffect(() => {
    if(dueDate) {
      setValue('dueDate', dueDate);
    } else {
      reset({dueDate});
    }
  }, [dueDate]);

  useEffect(() => {
    setValue('frequencyType', frequencyType);
  }, [frequencyType]);

  useEffect(() => {
    setValue('frequencyUnit', frequencyUnit);
  }, [frequencyUnit]);

  useEffect(() => {
    if(isOpen) {
      reset({
        companies: [],
        certificates: [],
        collaborators: [],
        priority: undefined,
        status: undefined,
        name: undefined,
        description: undefined,
        frequencyType: undefined,
        frequencyUnit: undefined,
        frequency: undefined,
        dueDate: undefined,
        calculationPeriod: undefined,
        jurisdiction: undefined,
        fine: undefined,
      });

      setDueDate(undefined);
      setFrequencyType(null);
      setFrequencyUnit(null);
      setFrequency(null);
      setJurisdiction(null);
      setIsOpenRemoveCompanies(false);
      setIsOpenRemoveCertificates(false);
      setSaveForAllCards('0');
      setSelectedLoteOption(ECompanyStatus.PENDENCY);
      setIsOpenModalSave(false);
      setFilteredCompanies(null);
      setCompanyChecked([]);
      setCompanyMetaData(undefined);
      setCompanies(null);
      setCompanyAll(null);
      setAllCompanies([]);
      setSelectedCompanies([]);
      setCheckedCompanies([]);
      setCertificatesUse([]);
      setCertificateMetaData(undefined);
      setCertificates([]);
      setCertificateList(null);
      setCertificateListAll(null);
      setReinitFilter(false);
      setSelectedCertificates([]);
      setCertificatesChecked([]);
      setCardDefeated(false);
      setCardCompanies([]);
      setHistory([]);
      setDisabledButtonSave(false);
      setCommentPanelReduced(false);
      setFilterDataSelected(null);
      setListCertificatesAlreadyLoaded(false);
      setListCompanyAlreadyLoaded(false);


      setCertificatesChecked([]);
      fetchCard();
    }
  }, [isOpen]);


  const columns = [
    {
      title: 'Status',
      name: 'status',
      sortable: false,
      align: 'center',
      alwaysField: true,
      width: card?.cardStructure ? "120px" : "140px",
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: '',
        defaultValue: ECompanyStatus.PENDENCY,
        className: 'form-control',
        required: true,
        disabled: () => {return false},
        options: card?.cardStructure ? [...optionsLote] : [...optionsLoteObligation]
      },
    },
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 'holding',
          color: '#ECFCCB',
          textColor: '#365314',
          minWidth: '100%',
        },
        {
          text: 'Filial',
          value: 'subsidiary',
          color: '#C4F1F9',
          textColor: '#065666',
          minWidth: '100%',
        },
      ],
    },
    {
      title: 'CNPJ',
      name: 'cnpj',
      formatter: formatCnpj,
      sortable: true,
      align: 'center',
    },
    {
      title: 'Nome - (Cód)',
      name: 'nameWithCode',
      orderFieldName: 'nameWithCode',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    {
      title: 'Arq.',
      name: 'file',
      sortable: false,
      align: 'center',
      width: '22px',
      customComponent: (row: any) => {
        if(!row.companyCard) {
          return <></>
        }
        return <CompanyAttachment
          currentCard={props.guid_card}
          guid_client={props.guid_client}
          association={row.companyCard.company}
          files={row.companyCard?.files ? row.companyCard.files.length : 0}
          disabled={cardDefeated}
        />
      }
    }
  ] as IColumn[];

  const columnsCertificateEdit = [
    {
      title: 'Status',
      name: 'status_card',
      sortable: false,
      align: 'center',
      alwaysField: true,
      width: "120px",
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: '',
        defaultValue: ECompanyStatus.PENDENCY,
        className: 'form-control',
        required: true,
        disabled: () => {return false},
        options: optionsLote
      },
    },
    {
      title: 'Certidão',
      name: 'certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: statusForTagList,
        elipse: {
          columnName: 'isNewStatus',
          tooltipTextTrue: '',
          tooltipTextFalse: '',
          tooltipColorTrue: '#365314',
          tooltipColorFalse: '#EF4444',
          showIfFalse: false,
          validation: (isNewStatus: boolean) => {
            return isNewStatus;
          }
        },
      },
    },
    {
      title: 'Titular',
      name: 'entity.displayName',
      orderFieldName: 'company.name,persona.name',
      sortable: true,
      align: 'center',
      hyperlink: (row: ICNDListData) => {
        setCurrentCertificate(row);
        setOpenHistoryModal(true);
      }
    },
    {
      title: 'Tipo',
      name: 'entity.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          textColor: '#365314',
          color: '#ECFCCB',
        },
        {
          text: 'Filial',
          value: 1,
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Pessoa Física',
          value: 3,
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
      ],
    },
    {
      title: 'CNPJ/CPF',
      name: 'entity.documentFormatted',
      orderFieldName: 'company.cnpj,persona.document',
      sortable: true,
      align: 'center',
      icon: (row: ICNDListData) => {
        if(!row.errorMessage) {
          return {
            tooltipText: '',
            iconUrl: '',
            show: false
          }
        }

        return {
          tooltipText: row.errorMessage,
          iconUrl: '/icons/alert.svg',
          show: true  
        }
      }
    },
    {
      title: 'Grupo',
      name: 'groups_names',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Emissão',
      name: 'issuedAtFormatted',
      orderFieldName: 'issuedAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Vencimento',
      name: 'validUntilFormatted',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Prazo',
      name: 'displayDeadline',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
      textColor: (row: ICNDListDataForCard) => {
        if(row.deadline && row.deadline < 30) {
          return '#fc1616';
        }
        return '#171923';
      }
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Estado',
      name: 'state',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    
  ] as IColumn[];

  // @ts-ignore
  return (
    <>
        <Modal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          size="7xl"
          blockScrollOnMount={false}
        >
          <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />

          <ModalContent
            flexGrow={1}
            flexShrink={1}
            borderLeft="12px solid var(--primary-500)"
            borderRadius={8}
            maxW="95%"
          >
            <Flex>
              <Flex
                flexDirection="column"
                w={commentPanelReduced ? "95%" : "65%"}
                position="relative"
              >
                <ModalHeader pl={4} fontFamily="Poppins-SemiBold" pb={3}>
                  <Flex>
                    Detalhes do card

                    <Flex w="32px" ml="5px">
                      <DeleteKanbanCardModal cardName={card?.name} callback={removeCard} />
                    </Flex>
                  </Flex>
                </ModalHeader>

                <ModalBody pl={4}>
                  <Flex flexDirection="column">
                    {!card?.cardStructure && (
                      <Flex flexDirection="row" className="w-100">
                        <TaxlyInput
                          fontFamily="Poppins-medium"
                          label={'Nome da automação'}
                          placeholder="Digite um nome para automação"
                          labelCustomStyle={{ mb: 1, fontWeight: 500 }}
                          isRequired={true}
                          _placeholder={{ color: '#CBD5E0' }}
                          value={card?.obligation?.title}
                          disabled={true}
                        />
                        {
                          errors.name && (
                            <Text color="red" fontSize={"12px"}>{errors.name.message}</Text>
                          )
                        }
                      </Flex>
                    )}

                    {card?.cardStructure && (
                      <Flex flexDirection="row" className="w-100" mt={!card?.cardStructure ? 5 : 0}>
                        <TaxlyInput
                          fontFamily="Poppins-medium"
                          label={'Nome do card'}
                          placeholder="Digite um título para o card"
                          labelCustomStyle={{ mb: 1, fontWeight: 500 }}
                          isRequired={true}
                          _placeholder={{ color: '#CBD5E0' }}
                          disabled={cardDefeated}
                          {...register("name")}
                        />
                        {
                          errors.name && (
                            <Text color="red" fontSize={"12px"}>{errors.name.message}</Text>
                          )
                        }
                      </Flex>
                    )}

                    <Flex flexDirection="column" position="relative" mt={5}>
                      <Flex
                        flexDirection="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        fontSize="12px"
                        mb={1}
                        fontWeight={500}
                        fontFamily={'Poppins-Medium'}
                      >
                        <label htmlFor="description">Descrição</label>
                      </Flex>

                      <Textarea
                        id="description"
                        placeholder="Escreva uma descrição para o card..."
                        isRequired={true}
                        fontSize='12px'
                        fontFamily='Poppins-medium'
                        borderRadius='4px'
                        border='1px solid #CBD5E0'
                        bgColor='transparent'
                        fontWeight={100}
                        bg="#FFFFFF"
                        _placeholder={{ color: '#CBD5E0' }}
                        disabled={cardDefeated}
                        {...register("description")}
                      />
                    </Flex>
                  </Flex>

                  <Flex flexDirection="row">
                    <Flex
                      mt={5}
                      gap={2}
                      zIndex={9999999}
                      justifyContent="space-between"
                      width={'100%'}
                    >
                      {card?.obligation?.jurisdiction && (
                        <Flex w="auto" alignItems="flex-end">
                            <Menu>
                              <Tag tag={getTag(card?.obligation?.jurisdiction)} />
                            </Menu>
                        </Flex>
                      )}

                      <Flex flexDirection="column" w="100%">
                        <label
                          htmlFor="dueDate"
                          style={{
                            fontSize: '12px',
                            flexDirection: 'column',
                            fontWeight: 500,
                            fontFamily: 'Poppins-Medium',
                            marginBottom: '5px',

                          }}
                        >
                          Data de Vencimento
                        </label>

                        <InputGroup
                          border="1px solid var(--chakra-colors-chakra-border-color)"
                          borderRadius={6}
                        >
                          <Flex flexDirection="column" w="100%" cursor={'pointer'}>
                            <DatePickerWithOptions
                              selectedDay={dueDate}
                              setSelectedDay={setDueDate}
                              setFrequencyType={setFrequencyType}
                              frequencyType={frequencyType}
                              setFrequencyUnit={setFrequencyUnit}
                              frequencyUnit={frequencyUnit}
                              disabled={!card?.cardStructure || cardDefeated}
                              placeholder="Data de vencimento"
                              id="dueDate"
                            />
                          </Flex>
                        </InputGroup>

                        {errors.dueDate && (
                          <Text color="red" fontSize={'12px'}>
                            {errors.dueDate.message}
                          </Text>
                        )}
                      </Flex>

                      {card?.cardStructure && (
                        <>
                          <Flex w="100%" flexDirection={'column'}>
                            <SelectWithIcon
                              id="priority"
                              label="Prioridade"
                              options={priorityOptions}
                              selected={selectedOption}
                              setSelectedOptions={setSelectedOption}
                              isDisabled={cardDefeated}
                            />

                            {errors.priority && (
                              <Text color="red" fontSize={'12px'}>
                                {errors.priority.message}
                              </Text>
                            )}
                          </Flex>

                          <Flex w="100%" flexDirection={'column'}>
                            <SelectWithIcon
                              id="status"
                              label="Status"
                              options={optionsLote}
                              selected={{
                                'label': status ? status.label : 'Status',
                                'value': status?.value,
                                'icon': <Image w="22px" h="22px" src="/icons/status-icon.svg" />
                              }}
                              setSelectedOptions={setStatus}
                            />

                            {errors.status && (
                              <Text color="red" fontSize={'12px'}>
                                {errors.status.message}
                              </Text>
                            )}
                          </Flex>
                        </>
                      )}


                      {!card?.cardStructure && (
                        <>
                          <Flex w="100%" flexDirection={'column'}>
                            <label
                              htmlFor="recurrency"
                              style={{
                                fontSize: '12px',
                                flexDirection: 'column',
                                fontWeight: 500,
                                fontFamily: 'Poppins-Medium',
                                marginBottom: '5px',

                              }}
                            >
                              Recorrência
                            </label>

                            <InputGroup
                              border="1px solid var(--chakra-colors-chakra-border-color)"
                              borderRadius={6}
                            >
                              <Menu>
                                <MenuButton
                                  as={Button}
                                  display="flex"
                                  alignItems="center"
                                  fontSize="12px"
                                  width="auto"
                                  w={"100%"}
                                  backgroundColor="white"
                                  p="4px 8px"
                                  justifyContent="flex-start"
                                  fontFamily={'Poppins-Medium'}
                                  fontWeight={500}
                                  height="31px"
                                  _hover={{ cursor: "pointer" }}
                                  rightIcon={<ChevronDownIcon />}
                                  disabled={true}
                                >
                                  <Flex alignItems="center" fontFamily={'Poppins-Medium'}>
                                    <Box as="span" mr="2">
                                      <Image w="22px" h="22px" src="/icons/recurrency.svg" />
                                    </Box>

                                    Controlado pelo sistema
                                  </Flex>
                                </MenuButton>
                              </Menu>
                            </InputGroup>
                          </Flex>
                        </>
                      )}

                      <Flex w="100%" flexDirection={'column'}>
                        <label
                          htmlFor="collaborator"
                          onClick={() => !cardDefeated ? setOpenSelectCollaborator(true) : null}
                          style={{
                            fontSize: '12px',
                            flexDirection: 'column',
                            fontWeight: 500,
                            fontFamily: 'Poppins-Medium',
                            marginBottom: '5px',
                          }}
                        >
                          Responsável
                        </label>

                        <InputGroup
                          border="1px solid var(--chakra-colors-chakra-border-color)"
                          borderRadius={6}
                        >
                          <Flex flexDirection="column" w="100%">
                            <Flex
                              fontSize="12px"
                              width="auto"
                              w={"100%"}
                              height="32px"
                              p="4px 8px"
                              justifyContent="flex-start"
                              alignItems="center"
                              cursor={!cardDefeated ? 'pointer' : 'not-allowed'}
                              onClick={() => !cardDefeated ? setOpenSelectCollaborator(true) : null}
                            >
                              <Image src="/icons/persona.svg"/>

                              <Link
                                ml="5px"
                                fontSize="12px"
                                fontFamily="Poppins-medium"
                                color="#4A5568"
                                cursor={!cardDefeated ? 'pointer' : 'not-allowed'}
                                _hover={{
                                  textDecoration: !cardDefeated ? 'underline' : 'none',
                                }}
                              >
                                {
                                  selectedsCollaborator.length > 0
                                    ? selectedsCollaborator.length > 1
                                      ? `${selectedsCollaborator.length} selecionados`
                                      : `${selectedsCollaborator.length} selecionado`
                                    : 'Responsável'
                                }
                              </Link>
                            </Flex>
                            {
                              errors.collaborators && (
                                <Text color="red" fontSize={"12px"}>{errors.collaborators.message}</Text>
                              )
                            }
                          </Flex>
                        </InputGroup>
                      </Flex>
                    </Flex>
                  </Flex>

                  {
                    card && card.companies && 
                      <Flex mt="15px" flexDirection="column">
                        <Filters
                          guid_client={props.guid_client}
                          filters={filterRows}
                          selectedValuesHook={setFilterDataSelected}
                        />

                        <Flex 
                          alignItems="stretch"
                          direction="column"
                          gap={2}
                          flexGrow={1}
                          mt={8}
                          mb={4} 
                          position="relative"
                        >
                          {
                            companyChecked.length > 0
                            && filteredCompanies
                            && filteredCompanies.data
                            && companyChecked.length !== companyAll?.data.length
                            && !cardDefeated
                            && (
                              <Flex
                                color="#EF4444"
                                fontFamily="Poppins-Medium"
                                fontSize="12px"
                                cursor="pointer"
                                position="absolute"
                                top="9px"
                                onClick={() => setIsOpenRemoveCompanies(true)}
                              >
                                <Text>X</Text>
                                <Text ml="10px">Remover selecionados: {companyChecked.length}</Text>
                              </Flex>
                            )
                          }

                          <Datatables
                            name="Empresas"
                            columns={columns}
                            metaDataHook={setCompanyMetaData}
                            data={filteredCompanies}
                            dataHook={setFilteredCompanies}
                            isLoading={loadingCompanyData}
                            showTotalRows={true}
                            maxHeigth="400px"
                            checkBox={{
                              selecteds: companyChecked,
                              setSelecteds: setCompanyChecked,
                              checkAll: selectAll,
                              key: 'guid_company'
                            }}
                            customButtons={
                              !cardDefeated && card.cardStructure ? [
                                {
                                  customComponent: 
                                    <SelectCompanies
                                      guid_client={props.guid_client}
                                      companies={allCompanies.filter((company) => !companyAll?.data.some((c) => c.guid_company === company.guid_company))}
                                      selectedCompanies={checkedCompanies}
                                      setSelectedCompanies={setCheckedCompanies}
                                      header="Selecionar Empresas"
                                      description=""
                                      buttonText="Adicionar Empresas"
                                      isLoading={loadingCompanyData}
                                      buttonOpenModalText="Adicionar mais empresas"
                                    />,
                                  pt: 1.5
                                }
                              ]
                              : 
                              []
                            }
                          />
                        </Flex>
                      </Flex>
                  }

                  {
                    card && card.certificates && 
                      <Flex mt="15px" flexDirection="column">
                        <Filters
                          guid_client={props.guid_client}
                          filters={filterRowsCertificates}
                          certificates={certificatesUse}
                          selectedValuesHook={setFilterDataSelected}
                          reinitFilter={reinitFilter}
                        />

                        <Flex
                          position="relative"
                          alignItems="stretch"
                          direction="column"
                          gap={2}
                          flexGrow={1}
                          mt={8}
                          mb={4}
                        >
                          {
                            certificatesChecked.length > 0
                            && certificates
                            && !cardDefeated
                            && certificatesChecked.length !== certificateListAll?.data.length
                            && (
                              <Flex
                                color="#EF4444"
                                fontFamily="Poppins-Medium"
                                fontSize="12px"
                                cursor="pointer"
                                position="absolute"
                                top="9px"
                                onClick={() => setIsOpenRemoveCertificates(true)}
                              >
                                <Text>X</Text>
                                <Text ml="10px">Remover selecionados: {certificatesChecked.length}</Text>
                              </Flex>
                            )
                          }

                          {
                            <Datatables
                              name="Certidões"
                              columns={columnsCertificateEdit}
                              metaDataHook={setCertificateMetaData}
                              data={certificateList}
                              dataHook={setCertificateList}
                              isLoading={!card}
                              showTotalRows={true}
                              maxHeigth="400px"
                              checkBox={{
                                selecteds: certificatesChecked,
                                setSelecteds: setCertificatesChecked,
                                checkAll: selectAllCertificates,
                                key: 'guid_cnd'
                              }}
                              customButtons={
                                !cardDefeated && card.cardStructure ? [
                                  {
                                    customComponent: 
                                      <SelectCertificates
                                        guid_client={props.guid_client}
                                        selectedCertificates={selectedCertificates}
                                        setSelectedCertificates={setSelectedCertificates}
                                        header="Selecione as certidões que deseja incluir"
                                        description=""
                                        buttonText="Adicionar Certidões"
                                        buttonOpenModalText={certificates.length ? "Adicionar mais certidões" : "Adicionar uma certidão"}
                                        excludeCertificate={excludeCertificatesGuid}
                                      />,
                                    pt: 1.5
                                  }
                                ]
                                :
                                []
                              }
                            />
                          }
                        </Flex>
                      </Flex>
                  }

                  <Flex
                    justifyContent="space-between"
                    gap={2}
                    alignItems="center"
                    display={
                      (
                        (companyChecked && companyChecked.length > 1) || 
                        (certificatesChecked && certificatesChecked.length > 1)
                      )
                      ? 'flex' : 'none'
                    }
                  >
                    <Flex display="contents" pr="10px">
                      <Select
                        size="xs"
                        fontSize={12}
                        value={selectedLoteOption}
                        borderColor="gray.500"
                        borderRadius="6px"
                        width="75px"
                        bg="white"
                        height="40px"
                        w="100%"
                        onChange={(e) => setSelectedLoteOption(e.target.value as ECompanyStatus)}
                      >
                        {
                          card?.cardStructure ? 
                          (
                            optionsLote.map((option) => (
                              <option value={option.value}>{option.label}</option>
                            ))
                          ) : (
                            optionsLoteObligation.map((option) => (
                              <option value={option.value}>{option.label}</option>
                            ))
                          )
                        }
                      </Select>
                    </Flex>

                    <Flex>
                      <Button
                        bgColor="#4B4EFF"
                        color="white"
                        width="auto"
                        height={37}
                        _hover={{ bg: '#282be0' }}
                        onClick={updateAllStatusCompany}
                      >
                        Confirmar operação
                      </Button>
                    </Flex>
                  </Flex>

                  <Flex
                    justifyContent="end"
                    mt="25px"
                  >
                    <Button
                      bgColor="white"
                      color="#171923"
                      _hover={{ bg: 'white', textDecoration: 'underline' }}
                      onClick={onClose}
                      mr={5}
                      fontSize={'14px'}
                      fontFamily={'Poppins-Medium'}
                      fontWeight={400}
                    >
                      Fechar
                    </Button>
                        
                    <Button
                      bgColor="#4B4EFF"
                      color="white"
                      width={133}
                      height={37}
                      _hover={{ bg: '#282be0' }}
                      onClick={validateConfirmationModal}
                      fontSize={'14px'}
                      fontFamily={'Poppins-Medium'}
                      fontWeight={400}
                      disabled={disabledButtonSave}
                      isDisabled={disabledButtonSave}
                      isLoading={disabledButtonSave}
                    >
                      Salvar alteração
                    </Button>
                  </Flex>
                </ModalBody>
              </Flex>

              <Flex
                m="16px"
                w={commentPanelReduced ? "auto" : "35%"}
                backgroundColor="#FAFAFA"
                flexDirection="column"
                mt="50px"
              >
                <CommentPanel
                  history={history}
                  guid_client={props.guid_client}
                  guid_card={props.guid_card}
                  comments={card?.comments}
                  refresh={fetchHistory}
                  setCommentPanelReduced={setCommentPanelReduced}
                />
              </Flex>
            </Flex>
            <ModalCloseButton />
          </ModalContent>
        </Modal>

      <SaveEditTask
        callback={handleSubmit(handleFormInfo)}
        saveForAllCardsHook={setSaveForAllCards}
        saveForAllCards={saveForAllCards}
        handleSubmit={handleSubmit}
        isOpen={isOpenModalSave}
        isOpenHook={setIsOpenModalSave}
      />

      <SelectCollaboratorModal
        isMulti={true}
        bigSelector={true}
        guid_client={props.guid_client}
        openModal={openSelectCollaborator}
        openModalHook={setOpenSelectCollaborator}
        callback={(collaborators: string[]) => setSelectedsCollaborator(collaborators)}
        selected={selectedsCollaborator}
        disabled={cardDefeated}
      />

      <ModalConfirm
        isOpen={isOpenRemoveCompanies}
        setIsOpen={setIsOpenRemoveCompanies}
        title={`Remover ${companyChecked.length} empresas`}
        description=""
        callback={removeCompanies}
      />

      <ModalConfirm
        isOpen={isOpenRemoveCertificates}
        setIsOpen={setIsOpenRemoveCertificates}
        title={`Remover ${certificatesChecked.length} certidões`}
        description=""
        callback={removeCertificates}
      />

      {currentCertificate ?
          <CertificateHistory
            guid_client={props.guid_client}
            certificate={currentCertificate}
            openModal={openHistoryModal}
            openModalHook={setOpenHistoryModal}
          />
        : null
      }
    </>
  );
};
