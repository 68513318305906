import queryString from "query-string";
import { IFilterListSchedule, IScheduleForList } from "../pages/dashboard/TaxKanban/interfaces/Schedule.interface";
import { ApiAuth, ApiCall } from "./api";
import { TPremiseResponse } from "./interface/IDefaults";
import {
    ICreateCardFields,
    IEditScheduleCardStructure,
    IGetCardsForCertificate,
    IGetCardsForCertificateResponse,
    ISendComments,
} from './interface/irs.interface'
import { IFilterListCertificate } from './interface/cnd.interface'
import { CancelToken } from 'axios'

export const listSchedule = async (guid_client: string, filters: IFilterListSchedule): Promise<TPremiseResponse<any>> => {
    const queryStringParams = queryString.stringify(filters);
    const url = `/irs/list/schedule/${guid_client}?${queryStringParams}`;
    return await new ApiCall(url, ApiAuth()).get<IScheduleForList[]>();
}; 

export const createCard = async(guid_client: string, formdata: ICreateCardFields): Promise<TPremiseResponse<any>> => {
    return await new ApiCall(`card-structure/create/${guid_client}`, ApiAuth()).post<any, ICreateCardFields>(formdata);
}

export const sendComments = async(guid_client: string, guid_card: string, data: ISendComments): Promise<TPremiseResponse<any>> => {
    const formData = new FormData();
    formData.append('text', data.text);
    
    if (data.mentions) {
        formData.append('mentions', JSON.stringify(data.mentions));
    }

    if (data.files) {
        Array.from(data.files).forEach((attachment, index) => {
            formData.append(`files`, attachment);
        });
    }

    return await new ApiCall(`comment/create/${guid_client}/card/${guid_card}`, ApiAuth({ "Content-Type": "multipart/form-data" })).post<any, FormData>(formData);
}

export const editCard = async(guid_client: string, guid_card_structure: string, guid_card: string, formdata: ICreateCardFields): Promise<TPremiseResponse<any>> => {
    return await new ApiCall(`card-structure/update/${guid_client}/${guid_card_structure}/${guid_card}`, ApiAuth()).post<any, ICreateCardFields>(formdata);
}

export const editScheduleCardStructure = async(guid_client: string, guid_card_structure: string, formdata: IEditScheduleCardStructure): Promise<TPremiseResponse<any>> => {
    return await new ApiCall(`card-structure/update/${guid_client}/${guid_card_structure}`, ApiAuth()).post<any, IEditScheduleCardStructure>(formdata);
}

export const getCard = async(guid_client: string, guid_card: string): Promise<TPremiseResponse<any>> => {
    return await new ApiCall(`/irs/kanban/${guid_client}/card/${guid_card}`, ApiAuth()).get<any>();
}

export const getCardsForCertificate = async(guid_client: string, data: IGetCardsForCertificate): Promise<TPremiseResponse<IGetCardsForCertificateResponse>> => {
    return await new ApiCall(`irs/kanban/${guid_client}`, ApiAuth()).post<IGetCardsForCertificateResponse, IGetCardsForCertificate>(data);
}