import { useEffect, useState } from "react";
import { FilterDataSelected } from "../../../../../components/Filters";
import { Datatables } from "../../../../../components/Table/Datatables";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../components/Table/table.interface";
import { IObligationResponse } from "../../interfaces/IObligation";
import moment from "moment";
import { BusinessDay } from "../../classes/BusinessDay";
import { TaxlyInput } from "../../../../../components/Input";


interface IAutomationSelectObligationsProps {
  guid_client: string;
  obligations: IObligationResponse[];
  isLoading: boolean;
  selecteds: any[];
  setSelecteds: (selecteds: any[]) => void;
}

export const AutomationSelectObligations = (props: IAutomationSelectObligationsProps) => {
  const [obligationChecked, setObligationChecked] = useState<string[]>([]);
  const [obligationMetaData, setObligationMetaData] = useState<ITableMetaData|undefined>();
  const [filteredObligations, setFilteredObligations] = useState<IData|null>(null);
  const [filter, setFilter] = useState<string>();

  //Calculates the dates for the Obligations
  const scheduleDateRule = (_line: IObligationResponse) => {
    if (_line.scheduleDate) {
      return moment.utc(_line.scheduleDate).format('DD');
    } else {
      if (_line.isDaily || _line.isAllMonth) {
        //If this obligation is daily the system will set to the last day of the month
        const b = new BusinessDay(parseInt(moment.utc().format("MM")), parseInt(moment.utc().format("YYYY")));
        return b.getLastBusinessDay().format('DD');
      } else {
        return "";
      }
    }
  }

  //Check if the date was redefined with the same rules as the function above
  const checkIfDateWasRedefined = (_line: IObligationResponse) => {
    if (_line.scheduleDate) {
      return false
    } else {
      if (_line.isDaily || _line.isAllMonth) {
        //If this obligation is daily the system will set to the last day of the month
        return true;
      } else {
        return false;
      }
    }
  }

  const columns = [
    {
      title: 'Dia',
      name: 'scheduleDate',
      sortable: true,
      align: 'center',
      formatter: (field: string) => {
        const date = new Date(field);
        if (isNaN(date.getTime())) {
          return field;
        }
    
        return date.getDate();
      },
      icon: (row: IObligationResponse) => {
        if(parseInt(scheduleDateRule(row)) < parseInt(moment.utc().format("DD"))) {
          return {
            tooltipText: 'Obrigações já vencidas para o calendário desse mês serão criadas como atrasadas',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        if(checkIfDateWasRedefined(row)) {
          return {
            tooltipText: 'Esta obrigação dura por todo o mês ou não possui dia específico, por esse motivo, o último dia útil do mês será utilizado como regra.',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        return {
          tooltipText: '',
          iconUrl: '',
          show: false
        }
      }
    },
    {
      title: 'DARF / GPS',
      name: 'code',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Descrição',
      name: 'title',
      sortable: true,
      align: 'center',
      reduce: 50,
    },
    {
      title: 'Período de Fato Gerador',
      name: 'generatingFactor',
      sortable: true,
      align: 'center',
    }
  ] as IColumn[];

  useEffect(() => {
    if (obligationMetaData && props.obligations) {
      const filteredObligations = filterObligations(props.obligations);
      const orderedObligations = sortObligations(obligationMetaData, filteredObligations, obligationChecked);

      const { currentPage, totalPages, rowByPage } = obligationMetaData;
      
      const data = orderedObligations;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedObligations.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedObligations.length
      }

      setFilteredObligations({
        data: paginatedData,
        meta
      });
    }
  }, [obligationMetaData, props.obligations, filter]);

  useEffect(() => {
    props.setSelecteds(obligationChecked);
  }, [obligationChecked])

  const sortObligations = (
    obligationMetaData: ITableMetaData, 
    obligations: IObligationResponse[], 
    obligationChecked: string[]
  ): IObligationResponse[] => {  
    // Mover os registros dentro de obligationChecked para o início do array
    const checkedObligations = obligations.filter(obligation => obligation.guid_darf && obligationChecked.includes(obligation.guid_darf));
    const uncheckedObligations = obligations.filter(obligation => obligation.guid_darf && !obligationChecked.includes(obligation.guid_darf));
  
    const orderField = obligationMetaData.orderField as keyof IObligationResponse;
    const orderDirection = obligationMetaData.orderDirection;
  
    const compareFunction = (a: IObligationResponse, b: IObligationResponse): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined || aValue === null || bValue === null) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    // Ordenar os uncheckedObligations de acordo com a orderField e orderDirection
    uncheckedObligations.sort(compareFunction);
    checkedObligations.sort(compareFunction);
  
    // Combinar as listas checkedObligations e sorted uncheckedObligations
    const sortedData = [...checkedObligations, ...uncheckedObligations];
    
    return sortedData;
  };

  const filterObligations = (orderedObligations: IObligationResponse[]): IObligationResponse[] => {
    let obligations = orderedObligations;
    if (orderedObligations.length > 0) {

      // Criando uma lista de empresas filtradas
      let filteredObligations = obligations;

      if (filter) {
        const lowerFilter = filter.toLowerCase();
        filteredObligations = filteredObligations.filter((row) => {
          const titleMatches = row.title && row.title.toLowerCase().startsWith(lowerFilter);
          const codeMatches = row.code && row.code.toLowerCase().startsWith(lowerFilter);
          
          return titleMatches || codeMatches;
        });
      }

      // Adicionando empresas selecionadas à lista filtrada
      filteredObligations = filteredObligations.concat(props.obligations.filter(obligation => obligation.guid_darf && obligationChecked.includes(obligation.guid_darf)));

      // Removendo duplicatas (se houver)
      filteredObligations = Array.from(new Set(filteredObligations));

      return filteredObligations;
    }

    return [];
  }

  const selectAll = () => {
    const filteredObligations = filterObligations(props.obligations);

    if (obligationChecked.length === filteredObligations.length && obligationChecked.length > 0) {
      setObligationChecked([]);
    } else {
      setObligationChecked(
        filteredObligations
          .map((x) => x.guid_darf)
          .filter((guid_darf): guid_darf is string => guid_darf !== null)
      );      
    }
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.currentTarget.value);
  };
  
  return (
    <>
      <TaxlyInput
        placeholder="Digite o código Darf ou nome da Obrigação para buscar"
        labelCustomStyle={{ mb: 1 }}
        isRequired={false}
        onChange={handleFilterChange}
        mb="15px"
      />
      <Datatables
        name="Obrigações fiscais"
        columns={columns}
        metaDataHook={setObligationMetaData}
        data={filteredObligations}
        isLoading={props.isLoading}
        showTotalRows={true}
        heigth="400px"
        checkBox={{
          selecteds: obligationChecked,
          setSelecteds: setObligationChecked,
          checkAll: selectAll,
          key: 'guid_darf'
        }}
      />
    </>
  );
};
