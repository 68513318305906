import { Flex, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, useToast, Text, ButtonGroup, Button, ModalFooter } from "@chakra-ui/react";
import { DashInputLabel } from "../../../Users/components/DashInput";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { getCompanyGroup, postCreateCompanyGroup, postEditCompanyGroup } from "../../../../../_services/companyGroup.service";
import { getClientCompanies } from "../../../../../_services/company.service";
import { IClientCompanies } from "../../../../../_services/interface/company.interface";
import { AddIcon } from "@chakra-ui/icons";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../../../../../components/Filters";
import { CompanyTypeEnum } from "../../../../../enums/CompanyTypeEnum";
import { IColumn, IData, IDataMeta, ITableMetaData } from "../../../../../components/Table/table.interface";
import { stateRegionForTag } from "../../../../../components/Table/utils";
import { Datatables } from "../../../../../components/Table/Datatables";
import { formatCnpj } from "../../Company/util/cnpj";


interface ICompanyCreateForm {
  name: string;
}

interface ICreateEditCompanyGroupModalProps {
  guid_client: string;
  guid_company_group?: string;
  flushHook: React.Dispatch<React.SetStateAction<boolean>>;
  openModal?: boolean;
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CreateEditCompanyGroupModal = (props: ICreateEditCompanyGroupModalProps) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const YupCompanyChangeForm = Yup.object().shape({
    name: Yup.string().required('Nome do grupo'),
  });
  const resolverForm = { resolver: yupResolver(YupCompanyChangeForm) };
  const { formState: { errors }, setValue, register, handleSubmit } = useForm<ICompanyCreateForm>(resolverForm);
  const [accErrorMessage, setAccErrorMessage] = useState<string[]>([]);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);
  const [companyChecked, setCompanyChecked] = useState<string[]>([]);
  const [isActive, setIsActive] = useState<boolean>(true);
  const [isConfigured, setIsConfigured] = useState<boolean>(true);

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [companies, setCompanies] = useState<IClientCompanies[]>([]);
  const [companiesFiltered, setCompaniesFiltered] = useState<IData|null>(null);
  const [companyMetaData, setCompanyMetaData] = useState<ITableMetaData|undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const configCompaniesForEdit = async () => {
    if (props.guid_client && props.guid_company_group) {
      const { status, response } = await getCompanyGroup(
          props.guid_client,
          props.guid_company_group
      );

      if (status === 200) {
        if (response?.companiesGroupsRelation) {
          const guidCompanies = response
              .companiesGroupsRelation
              .map((companyGroupRelation: any) => companyGroupRelation.guid_company);

          setCompanyChecked(guidCompanies);

          setValue('name', response.name);

          setIsActive(response.isActive);
          setIsConfigured(true);
        }
      }      
    }
  };

  const getCompanyData = async () => {
      if (props.guid_client) {
        setIsLoading(true);
        const { status, response } = await getClientCompanies(props.guid_client);

        if (status === 200) {
          if (Array.isArray(response)) {
            setCompanies(response as IClientCompanies[]);
          } else {
            toast({
              title: 'Ocorreu um erro',
              description: 'Ocorreu um erro ao tentar puxar as informações das empresas',
              status: 'error',
              duration: 5000,
              isClosable: true
            });
          }        
        }
        setIsLoading(false);
      }
  }

  const filterCompanies = (orderedCompanies: IClientCompanies[], filterDataSelected: FilterDataSelected): IClientCompanies[] => {
    let _cloned = orderedCompanies;

    if (orderedCompanies.length > 0) {
      if (filterDataSelected[FilterTypeEnum.companyFilter].length > 0) {
        _cloned = _cloned.filter(data =>
          filterDataSelected[FilterTypeEnum.companyFilter].some((option) => option === data.guid_company || companyChecked.includes(data.guid_company))
        );
      }

      if (filterDataSelected[FilterTypeEnum.companyStateFilter].length > 0) {
        _cloned = _cloned.filter(data =>
          filterDataSelected[FilterTypeEnum.companyStateFilter].some((option) => option === data.initials || companyChecked.includes(data.guid_company))
        );
      }

      if (filterDataSelected[FilterTypeEnum.companyCityFilter].length > 0) {
        _cloned = _cloned.filter(data =>
          filterDataSelected[FilterTypeEnum.companyCityFilter].some((option) => option === data.city || companyChecked.includes(data.guid_company))
        );
      }

      if (filterDataSelected[FilterTypeEnum.companyTypeFilter].length > 0) {
        _cloned = _cloned.filter(data =>
          filterDataSelected[FilterTypeEnum.companyTypeFilter].some((option) => option === CompanyTypeEnum[data.type.toString() as keyof typeof CompanyTypeEnum] || companyChecked.includes(data.guid_company))
        );
      }

      if (filterDataSelected[FilterTypeEnum.companyCodeFilter].length > 0) {
        _cloned = _cloned.filter(data =>
          filterDataSelected[FilterTypeEnum.companyCodeFilter].some((option) => option === data.companyCode || companyChecked.includes(data.guid_company))
        );
      }

      // Criando uma lista de empresas filtradas
      let filteredCompanies = _cloned;

      // Adicionando empresas selecionadas à lista filtrada
      filteredCompanies = filteredCompanies.concat(companies.filter(company => companyChecked.includes(company.guid_company)));

      // Removendo duplicatas (se houver)
      filteredCompanies = Array.from(new Set(filteredCompanies));

      return filteredCompanies;
    }

    return [];
  }

  const sortCompanies = (
    companyMetaData: ITableMetaData, 
    companies: IClientCompanies[], 
    companyChecked: string[]
  ): IClientCompanies[] => {  
    // Mover os registros dentro de companyChecked para o início do array
    const checkedCompanies = companies.filter(company => companyChecked.includes(company.guid_company));
    const uncheckedCompanies = companies.filter(company => !companyChecked.includes(company.guid_company));
  
    const orderField = companyMetaData.orderField as keyof IClientCompanies;
    const orderDirection = companyMetaData.orderDirection;
  
    const compareFunction = (a: IClientCompanies, b: IClientCompanies): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];
  
      if (aValue === undefined || bValue === undefined) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }
  
      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };
  
    // Ordenar os uncheckedCompanies de acordo com a orderField e orderDirection
    uncheckedCompanies.sort(compareFunction);
    checkedCompanies.sort(compareFunction);
  
    // Combinar as listas checkedCompanies e sorted uncheckedCompanies
    const sortedData = [...checkedCompanies, ...uncheckedCompanies];
    
    return sortedData;
  };

  const handleFormInfo = async (data: ICompanyCreateForm) => {
    setSubmitDisabled(true);

    if (data.name === '') {
      setAccErrorMessage(['Nome']);
      return;
    }

    if(companyChecked.length === 0) {
      setAccErrorMessage(['Empresas do grupo']);
      return;
    }

    let responseStatus = null;
    if(props.guid_company_group) {
      const { status, response } = await postEditCompanyGroup(
        props.guid_client,
        props.guid_company_group,
        {
          name: data.name,
          isActive: isActive,
          companies: companyChecked
        }
      );

      responseStatus = status;
    } else {
      const { status, response } = await postCreateCompanyGroup(props.guid_client, {
        name: data.name,
        isActive: true,
        companies: companyChecked
      });

      responseStatus = status;
    }

    
    if (responseStatus === 200) {
      toast({
        title: "Grupo de Empresa criado com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setCompanyChecked([]);
      setValue("name", '');
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: "Erro ao criar Grupo de Empresa",
        description: "Não foi possivel criar o grupo de empresas",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setSubmitDisabled(false);
  }

  const selectAll = () => {
    if(filterDataSelected) {
      const filteredCompanies = filterCompanies(companies, filterDataSelected);

      if (companyChecked.length === filteredCompanies.filter((company) => company.isActive).length && companyChecked.length > 0) {
        setCompanyChecked([]);
      } else {
        setCompanyChecked(filteredCompanies.filter((company) => company.isActive).map((x): string => x.guid_company));
      }
    }
  }

  useEffect(() => {
    if (companyMetaData && companies && filterDataSelected) {
      const filteredCompanies = filterCompanies(companies, filterDataSelected);
      const orderedCompanies = sortCompanies(companyMetaData, filteredCompanies, companyChecked);

      const { currentPage, totalPages, rowByPage } = companyMetaData;
      
      const data = orderedCompanies;
      const start = (currentPage - 1) * rowByPage;
      const end = start + rowByPage;
      const paginatedData = data.slice(start, end);

      const meta: IDataMeta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCompanies.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCompanies.length
      }

      setCompaniesFiltered({
        data: paginatedData,
        meta
      });
    }
  }, [companyMetaData, companies, filterDataSelected]);

  useEffect(() => {
    if (isOpen) {
      setCompanyChecked([]);
      
      configCompaniesForEdit();
      
      if (!props.guid_company_group) {
        getCompanyData();
      }
      
      setValue("name", '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (props.guid_company_group && isConfigured) {
      getCompanyData();
      setIsConfigured(false);
    }
  }, [isConfigured, companyChecked]);


  //Check Form Errors
  useEffect(() => {
    if (errors) {
      setAccErrorMessage(Object.values(errors).map((_x) => _x.message) as string[]);
    }
  }, [errors]);

  useEffect(() => {
    if(props.openModalHook && props.openModal ) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  
  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyTypeFilter,
          position: 2,
          size: 3,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 3,
          size: 3,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.companyStateFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyCityFilter,
          position: 2,
          size: 6,
        },
      ],
      position: 2
    },
  ] as FilterRow[];

  const columns = [
    {
      title: 'Razão Social - (cód.)',
      name: 'nameWithCode',
      orderFieldName: 'name',
      sortable: true,
      align: 'left',
      subTable: (row: IClientCompanies) => {return row.has_filial},
      reduce: 30,
    },
    {
      title: 'Tipo',
      name: 'type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 'holding',
          color: '#ECFCCB',
          textColor: '#365314'
        },
        {
          text: 'Filial',
          value: 'subsidiary',
          color: '#C4F1F9',
          textColor: '#065666'
        },
      ],
    },
    {
      title: 'CNPJ',
      name: 'cnpj',
      orderFieldName: 'cnpj',
      formatter: formatCnpj,
      sortable: true,
      align: 'center',
    },
    {
      title: 'Estado',
      name: 'initials',
      orderFieldName: 'initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city',
      orderFieldName: 'city',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
  ] as IColumn[];

  return (
    <>
      {(!props.openModalHook && !props.guid_company_group) && (
        <ButtonGroup onClick={onOpen} size="sm" mt="24px" isAttached color="white" >
          <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
            Criar novo grupo
          </Button>
        </ButtonGroup>
      )}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent borderRadius={8} borderLeft="12px solid #0263FF">
          <ModalHeader fontFamily="Poppins-Medium">
            {props.guid_company_group ? 'Editar grupo de empresa' : 'Criar grupo de empresa' }
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-Medium">
            {accErrorMessage.length > 0
                ? (
                    <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5} marginBottom="20px">
                      <Text>Por favor, informe os campos obrigatórios:</Text>
                      {accErrorMessage.map((_x, _i) => (
                        <Flex key={_i}>{_x}</Flex>
                      ))}
                    </Flex>
                ) : null
            }

            <Flex alignItems="stretch" direction="column" gap={5} flexGrow={1} pb={4}>
              <Flex>
                  <DashInputLabel
                      label={"Nome do grupo"}
                      labelCustomStyle={{ padding: '0 0 4px 0' }}
                      inputCustomStyle={{ fontFamily: 'Poppins-Light', padding: '8px 12px 8px 12px' }}
                      placeholder={"Digite um nome para o grupo de empresas"}
                      { ...register("name", { required: true }) }
                  />

                { props.guid_company_group
                  ?
                    <Flex
                        display="flex"
                        justifyContent="start"
                        cursor="pointer"
                        onClick={() => setIsActive(!isActive)}
                        marginTop={'1.5%'}
                    >
                      <Flex
                          display="flex"
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          marginLeft="10px"
                          marginRight="10px"
                      >
                        { !isActive
                            ? <Text fontSize={14}>Desativado</Text>
                            : <Text fontSize={14} color="#4B4EFF">Ativo</Text>
                        }
                      </Flex>

                      { !isActive
                          ? <Image w="50px" src="../icons/switch-gray.svg" />
                          : <Image w="50px" src="../icons/switch-blue.svg" />
                      }
                    </Flex>
                  : null
                }
              </Flex>
              
              <Flex>
                <Filters
                  guid_client={props.guid_client}
                  filters={filterRows}
                  selectedValuesHook={setFilterDataSelected}
                />
              </Flex>

              <Flex display="flex" flexDirection="column">
                <Datatables
                  name="Empresas"
                  columns={columns}
                  metaDataHook={setCompanyMetaData}
                  data={companiesFiltered}
                  isLoading={isLoading}
                  showTotalRows={true}
                  heigth="400px"
                  checkBox={{
                    selecteds: companyChecked,
                    setSelecteds: setCompanyChecked,
                    checkAll: selectAll,
                    key: 'guid_company'
                  }}
                />

              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex width="100%" justifyContent="end" alignItems="flex-start" display="flex" p="16px 24px">
              <FormButton onClick={handleSubmit(handleFormInfo)} disabled={submitDisabled} width="auto">
                {props.guid_company_group ? 'Editar grupo' : 'Criar grupo'}
              </FormButton> 
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}