import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import {
  BulkUpdateItemCertificateStatus,
  IBulkItemCertificate,
  IGetBulkUpdateResponse,
} from "../../../../../_services/interface/bulk-update.interface";
import { useCallback, useEffect, useState } from "react";
import { Alert } from "../../../../../components/Alert";
import { FormButton } from "../../../../../components/login/button.component";
import { Datatables } from "../../../../../components/Table/Datatables";
import { IColumn, IData, ITableMetaData } from "../../../../../components/Table/table.interface";
import { getBulkUpdateByBulkType, postFinishBulkUpdate } from "../../../../../_services/bulk-update.service";
import { EToastStatus } from "../../../../../components/ToastEnum";
import { cityForTagListUpdate, jurisdictionForTagListUpdate, stateRegionForTagListUpdate, statusForTagListUpdate, typeCompanyForTagListUpdate } from "../../util/cndUtils";
import { getCancelToken } from "../../../../../_services/cancelToken";
import { ICNDDataResponse, ICNDResponse } from "../../../../../_services/interface/cnd.interface";
import { reprocessCertificateErrorsBulkUpdate } from "../../../../../_services/cnd.service";
import debounce from 'lodash/debounce';

interface IBulkUpdateCertificatesModalProps {
  guid_client: string;
  bulkUpdate: IGetBulkUpdateResponse;
  showTable: boolean;
  setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
  setRefreshBulkUpdate: React.Dispatch<React.SetStateAction<boolean>>
  totalItems: number;
  totalFinished: number;
  totalErrors: number;
  setBulkUpdateRetryStarted: React.Dispatch<React.SetStateAction<boolean>>;
}

const BulkUpdateCertificatesModal = ({
  guid_client,
  showTable,
  setShowTable,
  setRefreshBulkUpdate,
  totalItems,
  totalFinished,
  totalErrors,
  setBulkUpdateRetryStarted,
}: IBulkUpdateCertificatesModalProps) => {
  const BULK_TYPE = "certificate";

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [bulkUpdateModal, setBulkUpdateModal] = useState<IGetBulkUpdateResponse>();
  const [bulkUpdateItems, setBulkUpdateItems] = useState<IData|null>(null);
  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();

  useEffect(() => {
    if (showTable) {
      onOpen();
      fetchBulkUpdateByType(guid_client);
      setRefreshBulkUpdate(true);
    }
  }, [showTable]);

  useEffect(() => {
    setRefreshData(true)
  }, [metaData]);

  useEffect(() => {
    setBulkUpdateItems(bulkUpdateModal?.items as IData);
  }, [bulkUpdateModal]);

  useEffect(() => {
  }, [totalItems, totalFinished, totalErrors]);

  const debouncedRefreshData = useCallback(debounce(() => setRefreshData(true), 1000), []);
  useEffect(() => {
    debouncedRefreshData();
  }, [totalItems, totalFinished, totalErrors]);

  useEffect(() => {
    if (refreshData) {
      fetchBulkUpdateByType(guid_client);
      setRefreshBulkUpdate(true);
      setRefreshData(false);
    }
  }, [refreshData]);

  const getFilterBulkUpdate = () => {
    return {
      page: metaData?.currentPage ?? 1,
      row_by_page: metaData?.rowByPage ?? -1,
      order_field: metaData?.orderField ?? 'guid_cnd',
      order_direction: metaData?.orderDirection ?? 'asc',
    };
  }

  const fetchBulkUpdateByType = async (guid_client: string) => {
    setIsLoading(true);
    if (guid_client && BULK_TYPE) {
      const filters = getFilterBulkUpdate();
      const { status, response } = await getBulkUpdateByBulkType(
        guid_client,
        BULK_TYPE,
        filters,
        getCancelToken('bulkUpdate.BulkUpdateByTypeModal')
      );

      if (status && status !== 200 && status !== 404) {
        toast({
          title: "Falha ao obter dados da página.",
          description: "Entre em contato com o suporte.",
          status: EToastStatus.ERROR,
          duration: 5000,
          isClosable: true,
        });
      }

      if (status && status === 200) {
        const bulkResponse = response as IGetBulkUpdateResponse
        setBulkUpdateModal(bulkResponse);
      }
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setRefreshBulkUpdate(true);
    onClose();
    setShowTable(false);
  };

  const showModalPanel = () => {
    toast.closeAll();

    let className = "alert-instance";
    let status: "warning" | "success" | "error" | "info" = "info";
    let descriptionHtml = `<strong>${
      (totalFinished)
    } de ${totalItems}</strong> certidões já foram atualizadas.`;
    let type: "two" | "three" | "one" = "three";
    let variant: "top-accent" | "solid" | "subtle" | "alert" | "left-accent" =
      "alert";
    let justify: "start" = "start";

    if (totalFinished === totalItems) {
      status = "success";
      descriptionHtml = `<strong>${totalFinished} de ${totalItems}</strong> certidões atualizadas.`;

      if (!!totalErrors) {
        status = "warning";
        descriptionHtml = `Erro ao atualizar <strong>${totalErrors} de ${totalItems}</strong> certidões.`;
      }
    }

    return (
      <>
        <Flex marginTop={"24px"}>
          <Alert
            isClosable={false}
            className={className}
            status={status}
            descriptionHtml={descriptionHtml}
            type={type}
            variant={variant}
            justify={justify}
          />
        </Flex>
      </>
    );
  };

  const finishBulkUpdate = async () => {
    const { status, response } = await postFinishBulkUpdate(
      guid_client,
      BULK_TYPE,
      bulkUpdateModal?.guid_bulk_update as string,
    );

    if (status === 200) {
      closeModal();
    } else {
      toast({
        title: "Falha ao concluir atualização em lote de certidões.",
        description: "Entre em contato com o suporte.",
        status: EToastStatus.ERROR,
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const shouldDisplayFinishButton = () => {
    const shouldDisplay = totalFinished === totalItems;
    if (shouldDisplay && !isFinished) {
      debouncedRefreshData();
      setIsFinished(true);
    }
    return shouldDisplay;
  }

  const canRetryErrors = () => {
    return shouldDisplayFinishButton() && bulkUpdateModal?.can_retry;
  }

  const processErrors = async () => {
    const { status, response } = await reprocessCertificateErrorsBulkUpdate(
      guid_client,
      bulkUpdateModal?.guid_bulk_update as string,
    );

    if (status === 200) {
      closeModal();
      setBulkUpdateRetryStarted(true);
    } else {
      toast({
        title: "Falha ao reprocessar erros.",
        description: "Entre em contato com o suporte.",
        status: EToastStatus.ERROR,
        duration: 5000,
        isClosable: true,
      });
    }
  }

  const datatableColumns = [
    {
      title: 'Certidão',
      name: 'cnd.certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'cnd.status',
      orderFieldName: 'cnd.slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: statusForTagListUpdate
      },
    },
    {
      title: 'Titular',
      name: 'cnd.entity_display_name',
      orderFieldName: 'entity_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Tipo',
      name: 'cnd.type_company',
      sortable: false,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          textColor: '#365314',
          color: '#ECFCCB',
        },
        {
          text: 'Filial',
          value: 1,
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Pessoa Física',
          value: 3,
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
      ],
    },
    {
      title: 'CNPJ/CPF',
      name: 'cnd.documentFormatted',
      sortable: false,
      align: 'center',
      icon: (row: IBulkItemCertificate) => {
        if (row.cnd && row.status !== BulkUpdateItemCertificateStatus.PENDING) {
          const rowCnd = row.cnd as ICNDDataResponse;
          if (row.status === BulkUpdateItemCertificateStatus.FAILED) {
            return {
              tooltipText: 'Falha na validação ao buscar a certidão',
              iconUrl: '/icons/alert.svg',
              show: true  
            };
          } else if(rowCnd.errorMessage) {
            return {
              tooltipText: rowCnd.errorMessage,
              iconUrl: '/icons/alert.svg',
              show: true  
            }
          }
        }

        return {
          tooltipText: '',
          iconUrl: '',
          show: false
        }
      }
    },
    {
      title: 'Emissão',
      name: 'cnd.issuedAtFormatted',
      orderFieldName: 'cnd.issuedAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Vencimento',
      name: 'cnd.validUntilFormatted',
      orderFieldName: 'cnd.validUntil',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Prazo',
      name: 'cnd.displayDeadline',
      orderFieldName: 'cnd.validUntil',
      sortable: true,
      align: 'center',
      textColor: (row: IBulkItemCertificate) => {
        const rowDeadline = row.cnd?.deadline;
        if(rowDeadline && rowDeadline < 30) {
          return '#fc1616';
        }
        return '#171923';
      }
    },
    {
      title: 'Jurisdição',
      name: 'cnd.jurisdiction',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: jurisdictionForTagListUpdate,
        field: 'cnd',
      }
    },
    {
      title: 'Estado',
      name: 'cnd.state',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTagListUpdate,
        field: 'cnd'
      },
    },
    {
      title: 'Cidade',
      name: 'cnd.city',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: cityForTagListUpdate,
        field: 'cnd'
      },
    },
  ] as IColumn[];

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeModal}
        size="7xl"
      >
        <ModalOverlay className="modal-overlay" />
        <ModalContent width={1335} className="modal-content">
          <ModalHeader width="100%" padding="8px 24px !important">
            <Text className="title">Atualização de Certidões</Text>

            {showModalPanel()}

          </ModalHeader>

          <ModalCloseButton onClick={closeModal} />

          <ModalBody fontFamily="Poppins-Medium">
            {totalFinished === 0 ?
              (<>
                <Text
                  fontSize="16px"
                  color="var(--black-gray-black-900)"
                  marginBottom="24px"
                >
                  Nenhuma certidão foi atualizada.
                </Text>
              </>) 
             : 
              <Datatables
                  name="Certidões"
                  columns={datatableColumns}
                  metaDataHook={setMetaData}
                  data={bulkUpdateItems}
                  orderField={'guid_cnd'}
                  orderDirection={'asc'}
                  isLoading={isLoading}
                />
            }
          </ModalBody>
          <ModalFooter>
            <Flex
              width="100%"
              justifyContent="end"
              alignItems="center"
              display="flex"
            >
              <Text
                sx={{
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
                height={"max-content"}
                cursor="pointer"
                color="var(--black-gray-black-900)"
                marginRight={'18px'}
                onClick={canRetryErrors() ? processErrors : closeModal }
              >
                { canRetryErrors() ? 'Reprocessar erros' : 'Fechar' }
              </Text>
              <Flex display={shouldDisplayFinishButton() ? undefined : 'none'}>
                <FormButton
                  onClick={finishBulkUpdate}
                  width="135px"
                >
                  Concluir
                </FormButton>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BulkUpdateCertificatesModal;
