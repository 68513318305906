import { ArrowDownIcon, CopyIcon, LinkIcon,  } from "@chakra-ui/icons";
import { Flex, Modal, ModalOverlay, ModalContent, Text, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image, useDisclosure, Alert, AlertIcon, useToast, Select, Tooltip, Button, Link } from "@chakra-ui/react"
import isEmpty from "is-empty";
import moment from "moment";
import { useEffect, useState } from "react";

import {
  EDisplay,
  IObligationResponseCustom,
  IScheduleData,
  IScheduleObligationData,
  TChangeLine,
  TJurisdiction,
} from '../interfaces/IObligation'
import { cutText } from "../utils/kanban.functions";
import { getScheduleInfo, patchSchedule, deleteSchedule } from "../_services/kanban.service";
import { DeleteModal } from "./Automation/DeleteModal";
import { checkClientPermission } from "../../Users/utils/checkPermission";
import useLoginFormStore from "../../../../store/useLoginFormStore";
import { IResponse } from "../../../../_services/interface/user.interface";
import { SelectCompanies } from "../../../../modal/SelectCompanies";
import { IClientCompanies } from "../../../../_services/interface/company.interface";
import { getClientCompanies } from "../../../../_services/company.service";
import { TaxlyInput } from '../../../../components/Input'
import { Row } from 'react-bootstrap'
import { Datatables } from '../../../../components/Table/Datatables'
import { BusinessDay } from '../classes/BusinessDay'
import { IAction, IColumn, IData, IDataMeta, ITableMetaData } from "../../../../components/Table/table.interface";
import { SelectCollaboratorModal } from '../../Users/modais/SelectCollaboratorModal';
import { BulkObligationExplanation } from './BulkObligationModal'

interface IEditScheduleModalProps {
  guid_client: string,
  guid_schedule: string,
  flushHook: React.Dispatch<React.SetStateAction<boolean>>,
  display?: EDisplay,
  ruleName?: string,
  icon?: boolean,
  openModal?: boolean,
  openModalHook?: React.Dispatch<React.SetStateAction<boolean>>,
}

const monthSmall = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec'];

export const EditScheduleModal = (props: IEditScheduleModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [ isSubmitDisabled, setSubmitDisabled ] = useState<boolean>(false);
  const [scheduleData, setScheduleData] = useState<IScheduleData>();
  const [allObligations, setObligationsData] = useState<IData|null>(null);
  const [fixedRule, setFixedRule] = useState<string>("toAntecipate");
  const [ruleName, setRuleName] = useState<string>("");
  const [companyList, setCompanyList] = useState<string[]>([]);
  const [simpleErrorMessage, setSimpleErrorMessage] = useState<string>("");
  const toast = useToast();
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isAutoCreating, setAutoCreation] = useState<boolean>(true);
  const { validate } = useLoginFormStore();
  const [allCompanies, setAllCompanies] = useState<IClientCompanies[]>([]);
  const [openSelectCompanies, setOpenSelectCompanies] = useState<boolean>(false);
  const [isLoadingCompany, setLoadingCompany] = useState<boolean>(true);
  const [selectedObligation, setSelectedObligation] = useState<IObligationResponseCustom | null>(null);
  const [selectedCollaborators, setSelectedCollaborators] = useState<string[]>([]);
  const [isResponsibleModalOpen, setIsResponsibleModalOpen] = useState(false);

  const getScheduleData = async () => {
    setLoadingState(true);
    const { status, response } = await getScheduleInfo(props.guid_client, props.guid_schedule);
    if (status === 200) {
      setScheduleData(response as IScheduleData);
      setCompanyList((response as IScheduleData).companies);

      const data =  (response as IScheduleData).obligations;
      const totalPages = Math.ceil(data.length / 10);


      const meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: 1 === totalPages,
        nextPage: 1 === totalPages ? null : 1 + 1,
        pageCount: Math.ceil(data.length / 10),
        previousPage: 1 === 1 ? null : 1 - 1,
        totalCount: data.length
      }

      const darfs = data.map((obligation) => {
        let scheduleDate: string|number = obligation.scheduleDate;
        if(obligation.rule === 'specifydate') {
          const date = new Date(obligation.scheduleDate);

          if(!isNaN(date.getTime())) {
            scheduleDate = new Date(obligation.scheduleDate).getDate()
          }
        }
        return {
          ...obligation,
          dueDate: obligation.rule,
          scheduleDate: scheduleDate,
          collaborators: obligation.collaborators,
          isDatatablesEditable: obligation.rule ? true : false,
          datatablesIdentify: obligation.guid_obligation
        }
      });

      setObligationsData({
        data: darfs,
        meta: meta
      });

      setFixedRule((response as IScheduleData).fixedRule);
      setRuleName((response as IScheduleData).rulename);
      setIsDisabled(!(response as IScheduleData).isActive);
      setAutoCreation((response as IScheduleData).autoGenerate);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações desta automação',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const applyReponsibleToObrigations = (collaborators: any) => {
    if (allObligations && selectedObligation) {
      const updatedObligations = allObligations.data.map((obligation) => {
        if (obligation.datatablesIdentify === selectedObligation?.datatablesIdentify) {
          return {
            ...obligation,
            collaborators,
          };
        }
        return obligation;
      });

      setObligationsData({
        data: updatedObligations,
        meta: allObligations.meta
      });
    }
    setSelectedObligation(null);
  }

  const loadCompanies = async () => {
    setLoadingCompany(true);
    const { status, response } = await getClientCompanies(props.guid_client);
    if (status === 200) {
      setAllCompanies(response as IClientCompanies[]);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações dos usuários',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingCompany(false);
  }

  useEffect(() => {
    if (isOpen) {
      getScheduleData();
      loadCompanies();
    }
  }, [isOpen]);

  const validateFields = (): boolean => {
    if(companyList.length === 0) {
      toast({
        title: 'Sem empresas selecionadas',
        description: 'Você pelo menos precisa ter uma empresa selecionada para criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(!allObligations || !allObligations.data) {
      toast({
        title: 'Sem obrigações selecionadas',
        description: 'Você pelo menos precisa ter uma obrigação selecionada para criar um agendamento.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    const obligationsToValidate = allObligations.data;

    // Validar todos os campos
    let noCollaborators = false;
    let noTitle = false;
    let noDuedate = false;
    let noScheduleDate = false;
    let noJurisdiction = false;
    let noFrequency = false;
    obligationsToValidate.map((obligation) => {
      if(!obligation.collaborators || obligation.collaborators.length === 0) {
        noCollaborators = true;
      }

      if(!obligation.guid_obligation && !obligation.dueDate) {
        noDuedate = true;
      }

      if(obligation.dueDate === 'specifydate' && !obligation.scheduleDate) {
        noScheduleDate = true;
      }

      if(!obligation.title) {
        noTitle = true;
      }

      if(!obligation.jurisdiction) {
        noJurisdiction = true;
      }

      if(!obligation.jurisdiction) {
        noJurisdiction = true;
      }

      if(!obligation.frequency) {
        noFrequency = true;
      }
    });

    if(noJurisdiction) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de uma jurisdição.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noFrequency) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de uma Frequência.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noCollaborators) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam de um responsável.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noTitle) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam ter nomes que as identificam.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noDuedate) {
      toast({
        title: 'Campos inválidos',
        description: 'Todas as obrigações precisam ter uma regra de replicação definida.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    if(noScheduleDate) {
      toast({
        title: 'Campos inválidos',
        description: 'Obrigações com datas específicas precisam de ter um dia definido.',
        status: 'error',
        duration: 2000,
        isClosable: true
      });

      return false;
    }

    return true;
  }

  const EditSchedule = async () => {
    setSubmitDisabled(true);
    if(!validateFields()) {
      setSubmitDisabled(false);
      return false;
    }

    const obligationsToSave: IScheduleObligationData[] = [];

    allObligations?.data.map((obligation) => {
      let day = obligation.scheduleDate
        ? new Date(obligation.scheduleDate).getUTCDate()
        : '';

      obligationsToSave.push({
        isCustom: obligation.isDatatablesEditable,
        guid_obligation: obligation.guid_obligation ?? '',
        day: String(day),
        month: '',
        code: obligation.code ?? '',
        title: obligation.title,
        rule: !obligation.guid_obligation ? obligation.dueDate : "",
        jurisdiction: obligation.jurisdiction,
        frequency: obligation.frequency,
        canEditDay: !obligation.guid_obligation ? true : false,
        collaborators: obligation.collaborators,
        scheduleDate: obligation.scheduleDate
      })
    });

    const { status } = await patchSchedule(props.guid_client, props.guid_schedule, {
      fixedRule, 
      ruleName, 
      autoGenerate: isAutoCreating,
      companies: companyList,
      allObligations: obligationsToSave,
      isDisabled
    });

    if (status === 200) {
      toast({
        title: 'Automação Editado com sucesso',
        description: 'Automação Editado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar editar a automação',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }

    setSubmitDisabled(false);
  }

  const totalDays: number[] = [];
  for(let i = 0; i < 15; i++) {
    totalDays.push(i+1);
  }

  const removeCard = async () => {
    setSubmitDisabled(true);
    setSimpleErrorMessage('');

    const { status } = await deleteSchedule(props.guid_client, props.guid_schedule);
    if (status === 200) {
      toast({
        title: 'Automação Removido com sucesso',
        status: 'success',
        isClosable: true,
        duration: 5000,
      });
      props.flushHook(true);
      onClose();
    } else {
      toast({
        title: 'Ocorreu um erro ao tentar remover a automação',
        description: 'Ocorreu um erro ao tentar remover a automação, por favor, tente mais tarde.',
        status: 'error',
        isClosable: true,
        duration: 5000,
      });
    }
  }

  const colorText = () => {
    return props.icon ? "" : "#4B4EFF";
  }

  useEffect(() => {
    if (props.openModal && props.openModalHook) {
      onOpen();
      props.openModalHook(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.openModal]);

  const scheduleDateRule = (_line: IObligationResponseCustom) => {
    if (_line.scheduleDate) {
      return moment.utc(_line.scheduleDate).format('DD');
    } else {
      if (_line.isDaily || _line.isAllMonth) {
        //If this obligation is daily the system will set to the last day of the month
        const b = new BusinessDay(parseInt(moment.utc().format("MM")), parseInt(moment.utc().format("YYYY")));
        return b.getLastBusinessDay().format('DD');
      } else {
        return "";
      }
    }
  }

  const checkIfDateWasRedefined = (_line: IObligationResponseCustom) => {
    if (_line.scheduleDate) {
      return false
    } else {
      return !!(_line.isDaily || _line.isAllMonth);
    }
  }

  const columns = [
    {
      title: 'Vencimento',
      name: 'dueDate',
      sortable: false,
      align: 'center',
      formatter: (field: string) => {
        switch (field) {
          case 'util_1': return '1° dia útil';
          case 'util_2': return '2° dia útil';
          case 'util_3': return '3° dia útil';
          case 'util_4': return '4° dia útil';
          case 'util_5': return '5° dia útil';
          case 'util_6': return '6° dia útil';
          case 'util_7': return '7° dia útil';
          case 'util_8': return '8° dia útil';
          case 'util_9': return '9° dia útil';
          case 'util_10': return '10° dia útil';
          case 'util_11': return '11° dia útil';
          case 'util_12': return '12° dia útil';
          case 'util_13': return '13° dia útil';
          case 'util_14': return '14° dia útil';
          case 'util_15': return '15° dia útil';
          case 'antepenultimate': return 'Ante-Penultimo dia útil';
          case 'penultimate': return 'Penúltimo dia útil';
          case 'lastday': return 'Último dia útil';
          case 'specifydate': return 'Especificar Dia';
          default: return '-';
        }
      },
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Regras',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: '1° dia útil',
            value: 'util_1'
          },
          {
            label: '2° dia útil',
            value: 'util_2'
          },
          {
            label: '3° dia útil',
            value: 'util_3'
          },
          {
            label: '4° dia útil',
            value: 'util_4'
          },
          {
            label: '5° dia útil',
            value: 'util_5'
          },
          {
            label: '6° dia útil',
            value: 'util_6'
          },
          {
            label: '7° dia útil',
            value: 'util_7'
          },
          {
            label: '8° dia útil',
            value: 'util_8'
          },
          {
            label: '9° dia útil',
            value: 'util_9'
          },
          {
            label: '10° dia útil',
            value: 'util_10'
          },
          {
            label: '11° dia útil',
            value: 'util_11'
          },
          {
            label: '12° dia útil',
            value: 'util_12'
          },
          {
            label: '13° dia útil',
            value: 'util_13'
          },
          {
            label: '14° dia útil',
            value: 'util_14'
          },
          {
            label: '15° dia útil',
            value: 'util_15'
          },
          {
            label: 'Ante-Penultimo dia útil',
            value: 'antepenultimate'
          },
          {
            label: 'Penúltimo dia útil',
            value: 'penultimate'
          },
          {
            label: 'Último dia útil',
            value: 'lastday'
          },
          {
            label: 'Especificar Dia',
            value: 'specifydate'
          },
        ]
      },
    },
    {
      title: 'Dia',
      name: 'scheduleDate',
      sortable: false,
      align: 'center',
      width: "100px",
      formatter: (field: string) => {
        const date = new Date(field);

        if (/^\d{1,2}$/.test(field)) {
          const date = new Date();
          date.setDate(parseInt(field, 10)); // Define o dia do mês para o valor fornecido
          return date.getDate();
        }

        if (isNaN(date.getTime())) {
          return field;
        }

        return date.getDate();
      },
      icon: (row: IObligationResponseCustom) => {
        if(parseInt(scheduleDateRule(row)) < parseInt(moment.utc().format("DD"))) {
          return {
            tooltipText: 'Obrigações já vencidas para o calendário desse mês serão criadas como atrasadas',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        if(checkIfDateWasRedefined(row)) {
          return {
            tooltipText: 'Esta obrigação dura por todo o mês ou não possui dia específico, por esse motivo, o último dia útil do mês será utilizado como regra.',
            iconUrl: '/icons/alert.svg',
            show: true
          }
        }

        return {
          tooltipText: '',
          iconUrl: '',
          show: false
        }
      },
      fieldDataToAdditionalRow: [
        {
          type: 'number',
          placeholder: 'Dia',
          defaultValue: '',
          name: 'scheduleDate',
          className: 'form-control',
          required: true,
          disabled: (row: any): boolean => {
            if(row.dueDate === 'specifydate') {
              return false;
            }
            return true;
          },
        }, 
        {
          type: 'select',
          placeholder: '',
          defaultValue: '',
          name: 'month',
          className: 'form-control',
          required: true,
          visible: (row: any): boolean => {
            if(row.frequency === 'yearly') {
              return true;
            }
            return false;
          },
          options: [
            {
              label: 'Jan',
              value: '1'
            },
            {
              label: 'Fev',
              value: '2'
            },
            {
              label: 'Mar',
              value: '3'
            },
            {
              label: 'Abr',
              value: '4'
            },
            {
              label: 'Mai',
              value: '5'
            },
            {
              label: 'Jun',
              value: '6'
            },
            {
              label: 'Jul',
              value: '7'
            },
            {
              label: 'Ago',
              value: '8'
            },
            {
              label: 'Set',
              value: '9'
            },
            {
              label: 'Out',
              value: '10'
            },
            {
              label: 'Nov',
              value: '11'
            },
            {
              label: 'Dez',
              value: '12'
            },
          ]
        }
      ],
    },
    {
      title: 'DARF / GPS',
      name: 'code',
      sortable: false,
      align: 'center',
      fieldDataToAdditionalRow: {
        type: 'number',
        placeholder: 'DARF / GPS',
        defaultValue: '',
        className: 'form-control',
        required: true,
      },
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: false,
      align: 'center',
      width: "130px",
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Jurisdição',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: 'Federal',
            value: 'federal'
          },
          {
            label: 'Estadual',
            value: 'state'
          },
          {
            label: 'Municipal',
            value: 'municipal'
          }
        ]
      },
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Frequência',
      name: 'frequency',
      sortable: false,
      align: 'center',
      width: "130px",
      formatter: (field: string) => {
        switch (field) {
          case "weekly":
            return "Semanal";

          case "controlled":
            return "Controlado";

          case "monthly":
            return "Mensal";

          case "quarterly":
            return "Trimestral";

          case "semiannual":
            return "Semestral";

          case "yearly":
            return "Anual";

          case "onetime":
            return "Única";

          default:
            return "-";
        }
      },
      fieldDataToAdditionalRow: {
        type: 'select',
        placeholder: 'Frequência',
        defaultValue: '',
        className: 'form-control',
        required: true,
        options: [
          {
            label: 'Mensal',
            value: 'monthly'
          },
          {
            label: 'Trimestral',
            value: 'quarterly'
          },
          {
            label: 'Semestral',
            value: 'semiannual'
          },
          {
            label: 'Anual',
            value: 'yearly'
          },
          {
            label: 'Única',
            value: 'onetime'
          }
        ]
      },
    },
    {
      title: 'Descrição',
      name: 'title',
      sortable: false,
      align: 'center',
      reduce: 35,
      fieldDataToAdditionalRow: {
        type: 'text',
        placeholder: 'Descrição',
        defaultValue: '',
        className: 'form-control',
        required: true,
      },
    },
    {
      title: 'Responsável',
      name: 'collaborators',
      sortable: false,
      align: 'center',
      formatter: (field: any) => {
        return field?.length ?? 0;
      },
      fieldDataToAdditionalRow: {
        type: 'text_line',
        placeholder: 'Responsável',
        defaultValue: '',
        className: 'form-control',
        required: true,
        formatter: (value: any) => {
          if(Array.isArray(value)) {
            return value.length;
          }

          if(!value) {
            return 0;
          }

          return value ?? 0;
        }
      },
    }
  ] as IColumn[];


  const actions = [
    {
      text: 'Adicionar responsável',
      icon: <Image src="/icons/table/user.svg" width={5} height="auto" />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {return true},
      isDisabled: (obligation: IObligationResponseCustom): boolean => {return false},
      action: (obligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        setSelectedObligation(obligation);
        setSelectedCollaborators(obligation.collaborators);

        setIsResponsibleModalOpen(true);
      },
    },
    {
      text: 'Replicar para a linhas a baixo',
      icon: <ArrowDownIcon boxSize={4} />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {
        if(index === 0) {
          return true;
        }

        return false;
      },
      isDisabled: (obligation: IObligationResponseCustom): boolean => { return false },
      action: (currentObligation: IObligationResponseCustom, fatherRow: any, index: number, rows: any) => {
        if (rows) {
          const updatedAdditionalRowsValues = rows.map((row: any) => {
            row['collaborators'] = currentObligation.collaborators;

            return row;
          });

          if(allObligations?.meta) {
            setObligationsData({
              data: updatedAdditionalRowsValues,
              meta: allObligations.meta
            });
          }
        }
      }
    },
    {
      text: 'Copiar responsáveis da linha de cima',
      icon: <CopyIcon />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {
        if(index > 0) {
          return true;
        }

        return false;
      },
      isDisabled: (obligation: IObligationResponseCustom): boolean => { return false },
      action: (currentObligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        if(allObligations && setObligationsData) {
          let data = rows;
          const previousObligation = data[index - 1];

          data[index]['collaborators'] = previousObligation['collaborators'];

          if(allObligations?.meta) {
            setObligationsData({
              data: data,
              meta: allObligations.meta
            });
          }
        }
      }
    }, {
      text: 'Remover obrigação',
      icon: <Image src="/icons/table/minus.svg" width={5} height="auto" />,
      isVisible: (obligation: IObligationResponseCustom, fatherRow: any, index: number): boolean => {return true},
      isDisabled: (obligation: IObligationResponseCustom): boolean => {return false},
      action: (obligation: IObligationResponseCustom, fatherRow: any, index: number, rows: IObligationResponseCustom[]) => {
        if (allObligations?.meta) {
          const updatedObligations = [...rows];

          updatedObligations.splice(index, 1);
          setObligationsData({
            data: updatedObligations,
            meta: allObligations.meta
          });
        }
      },
    },
  ] as IAction[];

  return (
    <>
      {props.display && props.display === EDisplay.buttonEdit ? (
        <Flex width="26px" height="26px" resize="none">
          <Image src="../icons/edit.svg" onClick={onOpen} _hover={{ cursor: "pointer" }} />
        </Flex>
      ) : null}

      {props.display && props.display === EDisplay.ruleName && props.icon ? (
        <Flex alignItems="center">
          <Flex backgroundColor="#e2e8f0" alignItems="center" justifyContent="center" p="2px 8px" fontSize="10px" textTransform="uppercase" fontWeight="bold" borderRadius={5}>
              <LinkIcon color="#4B4EFF" />
            <Tooltip label={props.ruleName}>
              <Text cursor="pointer" marginLeft="5px" color={colorText()} textAlign="left" onClick={onOpen}>{cutText(props.ruleName ? props.ruleName : '', 20)}</Text>
            </Tooltip>
          </Flex>
        </Flex>
      ) : null}

      {props.display && props.display === EDisplay.ruleName && !props.icon ? (
        <Flex alignItems="center">
          <Tooltip label={props.ruleName}>
            <Text cursor="pointer" marginLeft="5px" color="#4B4EFF" textAlign="left" onClick={onOpen}>{props.ruleName}</Text>
          </Tooltip>
        </Flex>
      ) : null}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' alignItems="center" />
        <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>

          <ModalHeader flexDirection="row" fontFamily="Poppins-Medium" fontWeight={500}  w="95%">
            <Flex>
              Editar regra de automação &nbsp;

              {
                checkClientPermission(validate as IResponse, props.guid_client, "kanban.delete") ? (
                  <DeleteModal callback={removeCard} title="Deletar Automação"/>
                ): null
              }
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody fontFamily="Poppins-medium" fontSize={12}>

            <Flex justifyContent="center" flexDirection="column" gap={5}>
              <Alert
                status="warning"
                backgroundColor="#FEF9C3"
                color="#171923"
                border="1px solid #EAB308"
                fontSize="14px"
                borderRadius="6px"
                fontFamily="Poppins-Medium"
                boxShadow="0px 4px 4px 0px #00000040"
              >
                <Image src="/icons/warning.svg" mr={3} />
                As informações alteradas desta regras, quando salvas, serão aplicadas apenas no próxima automação.
              </Alert>

              {simpleErrorMessage ? (
                <Flex bg="#FFCFCF" color="#A93333" alignItems="flex-start" flexDirection="column" fontSize="12px" p={2} borderRadius={5}>
                  <Flex>{simpleErrorMessage}</Flex>
                </Flex>
              ) : null}

              <Flex flexDirection="row" gap={4} >
                <Flex width="75%">
                  <TaxlyInput
                    placeholder="Nome do agendamento"
                    label={'Nome do agendamento'}
                    labelCustomStyle={{ mb: 1 }}
                    value={ruleName}
                    borderColor="gray.300"
                    isRequired={true}
                    onChange={(e: any) => setRuleName(e.target.value)}
                  />
                </Flex>

                <Flex width="25%" alignItems="end">
                  {isLoadingCompany && !scheduleData ? (
                      <Flex border="1px solid" fontSize="12px" width="auto" w={"100%"} borderColor="gray.300" borderRadius={5} backgroundColor="gray.100" p={2}>
                        Carregando Empresas
                      </Flex>
                    ) :
                    <Flex
                      border="1px solid"
                      fontSize="12px"
                      w={"100%"}
                      borderColor="gray.300"
                      borderRadius={5}
                      h="40px"
                    >
                      <Link
                        width="100%"
                        color="#4B4EFF"
                        h="40px"
                        fontWeight="500"
                        alignItems="center"
                        onClick={() => setOpenSelectCompanies(true)}
                      >
                        <Flex gap={2} pl={2.5} justifyContent={'start'} alignItems="center" h="40px">
                          <Image src="/icons/company.svg" w="24px" />

                          {
                            companyList.length > 0
                              ? companyList.length > 1
                                ?  `${companyList.length} empresas selecionadas`
                                : `${companyList.length} empresa selecionada`
                              : 'Selecione a Empresa'
                          }
                        </Flex>
                      </Link>
                    </Flex>
                  }

                  <SelectCompanies
                    guid_client={props.guid_client}
                    companies={allCompanies}
                    open={openSelectCompanies}
                    setOpen={setOpenSelectCompanies}
                    selectedCompanies={companyList}
                    setSelectedCompanies={setCompanyList}
                    header="Selecionar Empresas"
                    description=""
                    buttonText="Salvar alteração"
                    isLoading={isLoadingCompany}
                  />
                </Flex>
              </Flex>

              <Flex justifyContent="space-between" gap={4} flexDirection="column">
                <Flex
                  align="center"
                  _hover={{ cursor: "pointer" }}
                  gap={4}
                  onClick={() => setIsDisabled(!isDisabled)}
                  maxW="100%"
                  color={isDisabled ? 'black' : '#4B4EFF'}
                >
                  {
                    isDisabled ? (
                      'Inativo'
                    ) : (
                      'Ativo'
                    )
                  }

                  {
                    isDisabled ? (
                      <Image w="51px" h="31px" src="/icons/switch-gray.svg" />
                    ) : (
                      <Image w="51px" h="31px" src="/icons/switch-blue.svg" />
                    )
                  }

                  <Text
                    align="center"
                    fontSize="12px"
                    fontFamily="Poppins-Medium"
                    fontWeight={400}
                    color={'black'}
                    display="flex"
                    alignItems="center"
                  >
                    <Image src="/icons/warning.svg" w={'13px'} mr={1} />
                    Caso o agendamento seja desativado, o sistema não criará as
                    tarefas de acordo com as obrigações e recorrências definidas.
                  </Text>
                </Flex>
              </Flex>

              <Text fontSize={16} fontWeight={600}>Definir Regras de Agendamento Automático</Text>

              <Datatables
                name="Obrigações fiscais"
                columns={columns}
                actions={actions}
                data={allObligations}
                dataHook={setObligationsData}
                showTotalRows={true}
                heigth="400px"
                pagination={false}
                rowByPage={false}
                customButtons={[
                  {
                    label: 'Adicionar nova linha',
                    id: 'manual-create',
                    className: 'btn btn-primary',
                    action: 'addNewRow',
                    mr: 3
                  },
                  {
                    label: 'Cadastro via excel',
                    id: 'manual-create',
                    className: 'btn btn-primary ',
                    action: 'uploadXslx',
                    mr: 3
                  },
                  {
                    customComponent: <BulkObligationExplanation />,
                    pt: 1.5
                  }
                ]}
              />

              <SelectCollaboratorModal
                isMulti={true}
                bigSelector={true}
                guid_client={props.guid_client}
                openModal={isResponsibleModalOpen}
                openModalHook={setIsResponsibleModalOpen}
                callback={(collaborators) => applyReponsibleToObrigations(collaborators)}
                selected={selectedCollaborators}
              />

              {allObligations && allObligations?.data && allObligations.data.some((e) => e.dueDate === 'specifydate') ? (
                <>
                  <hr></hr>

                  <Alert status="warning" justifyContent="space-between" gap="200px">
                    <Flex alignItems="center">
                      <AlertIcon />
                      Uma ou mais obrigações possuem dias específicos. Selecione como o sistema se comportará quando no próxima automação o dia cair como feriado ou final de semana.
                    </Flex>
                    <Flex>
                      <Select size="xs" fontSize={12} borderColor="gray.500" value={fixedRule} onChange={(e) => setFixedRule(e.target.value)} w={150} bg="white">
                        <option value="toAntecipate">Antecipar</option>
                        <option value="postpone">Adiar</option>
                      </Select>
                    </Flex>
                  </Alert>
                </>
              ) : null}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              bgColor="white"
              color="#171923"
              _hover={{ bg: 'white', textDecoration: 'underline' }}
              onClick={onClose}
              mr={5}
            >
              Fechar
            </Button>

            <Button
              bgColor="#4B4EFF"
              color="white"
              disabled={isSubmitDisabled}
              height={37}
              _hover={{ bg: '#282be0' }}
              onClick={EditSchedule}
            >
              Salvar alteração
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}