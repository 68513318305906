import { Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from "react";
import { FilterDataSelected, Filters } from '../../../../../../components/Filters';
import { Datatables } from '../../../../../../components/Table/Datatables';
import { IData, IDataMeta, ITableMetaData } from '../../../../../../components/Table/table.interface';
import { ModalConfirm } from '../../../../../../modal/ModalConfirm';
import { SelectCertificates } from '../../../../../../modal/SelectCertificates';
import { getClientCertificates } from '../../../../../../_services/certificate.service';
import { ECompanyStatus } from '../../../../../../_services/enum/irs.enum';
import { ICertificate } from '../../../../../../_services/interface/certificate.interface';
import { ICNDListData } from '../../../../../../_services/interface/cnd.interface';
import { filterCertificate, orderCertificate } from '../../../../CNDManager/util/cndUtils';
import { columnsCertificate, filterRowsCertificates } from '../Util/FiltersAndColumns';

interface ICertificateStaticFilterAndTable {
  guid_client: string;
  error: boolean;
  setSelectedCertificates: React.Dispatch<React.SetStateAction<ICNDListData[]>>;
  dataCertificate?: ICNDListData;
}

export const CertificateStaticFilterAndTable = (props: ICertificateStaticFilterAndTable) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  const [certificatesUse, setCertificatesUse] = useState<ICertificate[]>([]);
  const [certificateChecked, setCertificateChecked] = useState<string[]>([]);
  const [certificateList, setCertificateList] = useState<IData|null>(null);
  const [certificates, setCertificates] = useState<ICNDListData[]>([]);
  const [selectedCertificates, setSelectedCertificates] = useState<ICNDListData[]>([]);
  const [certificateMetaData, setCertificateMetaData] = useState<ITableMetaData|undefined>();
  const [isOpenRemoveCertificates, setIsOpenRemoveCertificates] = useState<boolean>(false);

  const [reinitFilter, setReinitFilter] = useState<boolean>(false);
  
  const selectAllCertificates = () => {
    if(certificates.length === certificateChecked.length) {
      setCertificateChecked([]);
    } else {
      setCertificateChecked(certificates.map((certificate) => certificate.guid_cnd));
    }
  }

  const fetchCertificates = async () => {
    const { status, response } = await getClientCertificates(props.guid_client);
    if (status === 200) {
      setCertificatesUse(response as ICertificate[]);
    }
  }

  useEffect(() => {
    fetchCertificates();
    if(props.dataCertificate) {
      setCertificates([props.dataCertificate]);
    }
    setReinitFilter(true);
  }, [props.dataCertificate])

  const updateListCertificate = () => {
    const totalPages = Math.ceil(certificates.length / 10);

    if(!certificateMetaData) {
      const meta: IDataMeta = {
        currentPage: 1,
        isFirstPage: true,
        isLastPage: totalPages === 1,
        nextPage: totalPages > 1 ? 2 : null,
        pageCount: totalPages,
        previousPage: null,
        totalCount: certificates.length
      };

      const certificateForCard = certificates.map((row) => {
        let certificateInList: any = null;
        if(certificateList && certificateList.data) {
          certificateInList = certificateList.data.find((certificate) => certificate.guid_cnd === row.guid_cnd);
        }
        
        return {
          ...row,
          status_card: certificateInList ? certificateInList.status_card : ECompanyStatus.PENDENCY,
        }
      });
  
      setCertificateList({
        data: certificateForCard,
        meta: meta
      });

      return;
    }

    let meta: IDataMeta;
    meta = {
      currentPage: certificateMetaData.currentPage ?? 1,
      isFirstPage: certificateMetaData.currentPage === 1,
      isLastPage: certificateMetaData.currentPage === certificateMetaData.totalPages,
      nextPage: certificateMetaData.currentPage === certificateMetaData.totalPages ? null : 1 + 1,
      pageCount: totalPages,
      previousPage: certificateMetaData.currentPage === 1 ? null : 1 - 1,
      totalCount: certificates.length
    };

    const certificateFiltered = filterCertificate(certificates, filterDataSelected);
    const certificateOrdered = orderCertificate(certificateFiltered, certificateMetaData.orderField as keyof ICNDListData, certificateMetaData.orderDirection as 'desc'|'asc');

    const certificateForCard = certificateOrdered.map((row) => {
      let certificateInList: any = null;
      if(certificateList && certificateList.data) {
        certificateInList = certificateList.data.find((certificate) => certificate.guid_cnd === row.guid_cnd);
      }
      
      return {
        ...row,
        status_card: certificateInList ? certificateInList.status_card : ECompanyStatus.PENDENCY,
      }
    });

    setCertificateList({
      data: certificateForCard,
      meta: meta
    });
  }

  const removeCertificates = () => {
    const newCertificates = certificates.filter((certificate) => !certificateChecked.includes(certificate.guid_cnd));
    setCertificates(newCertificates);
    setCertificateChecked([]);
  }

  useEffect(() => {
    updateListCertificate()
    props.setSelectedCertificates(certificates)
  }, [certificates, certificateMetaData, filterDataSelected]);

  useEffect(() => {
    setCertificates((prevCertificates) => {
      const allCertificates = [...prevCertificates, ...selectedCertificates];
      
      const uniqueCertificates = Array.from(
        new Map(allCertificates.map((item) => [item.guid_cnd, item])).values()
      );

      return uniqueCertificates;
    });
  }, [selectedCertificates]);

  useEffect(() => {
    setTimeout(() => {
      setReinitFilter(false);
    }, 1000);
  }, [reinitFilter])
  
  return (
    <>
      {certificateList && certificateList.data.length > 0 && (
        <Flex mb={8}>
          <Filters
            guid_client={props.guid_client}
            filters={filterRowsCertificates}
            certificates={certificatesUse}
            selectedValuesHook={setFilterDataSelected}
            reinitFilter={reinitFilter}
          />
        </Flex>
      )}

      {
        props.error && (
          <Text color="red" fontSize={"12px"}>
            Selecione ao menos 1 certidão
          </Text>
        )
      }

      <Flex
        position="relative"
        alignItems="stretch"
        direction="column"
        gap={2}
        flexGrow={1}
        mb={4}
      >
        {
          certificateChecked.length > 0
          && certificates
          && (
            <Flex
              color="#EF4444"
              fontFamily="Poppins-Medium"
              fontSize="12px"
              cursor="pointer"
              position="absolute"
              top="9px"
              onClick={() => setIsOpenRemoveCertificates(true)}
            >
              <Text>X</Text>
              <Text ml="10px">Remover selecionados: {certificateChecked.length}</Text>
            </Flex>
          )
        }

        {
          <Datatables
            name="Certidões"
            columns={columnsCertificate}
            metaDataHook={setCertificateMetaData}
            data={certificateList}
            isLoading={false}
            showTotalRows={true}
            maxHeigth="400px"
            checkBox={{
              selecteds: certificateChecked,
              setSelecteds: setCertificateChecked,
              checkAll: selectAllCertificates,
              key: 'guid_cnd'
            }}
            customButtons={
              [
                {
                  customComponent: 
                    <SelectCertificates
                      guid_client={props.guid_client}
                      selectedCertificates={selectedCertificates}
                      setSelectedCertificates={setSelectedCertificates}
                      header="Selecione as certidões que deseja incluir"
                      description=""
                      buttonText="Adicionar Certidões"
                      buttonOpenModalText={certificates.length ? "Adicionar mais certidões" : "Adicionar uma certidão"}
                      onlyCompanies={true}
                    />,
                  pt: 1.5
                }
              ]
            }
          />
        }
      </Flex>

      <ModalConfirm
        isOpen={isOpenRemoveCertificates}
        setIsOpen={setIsOpenRemoveCertificates}
        title={`Remover ${certificateChecked.length} certidões`}
        description=""
        callback={removeCertificates}
      />
    </>
  );
};
