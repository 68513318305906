import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  ButtonGroup,
  useToast,
  Text,
  ModalFooter,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../../../../../components/login/button.component";
import { ReloadIcon } from "../../../TaxKanban/Icons/Reload";
import { listCnds, retrieveCertificaesByGuidCnd } from "../../../../../_services/cnd.service";
import { ICNDListData, IFilterListCertificate } from "../../../../../_services/interface/cnd.interface";
import { RetrieveCertificatesStyle } from "../../../../../styles/components/RetrieveCertificates";
import { getCertificateLimit } from "../../../../../_services/client.service";
import { statusForTagList } from "../../util/cndUtils";
import '../../../../../styles/components/customStyle.css';
import './RetrieveCertificates.css';
import { ICertificate } from "../../../../../_services/interface/certificate.interface";
import moment from "moment";
import { Filters, FilterDataSelected, FilterRow, FilterTypeEnum } from "../../../../../components/Filters";
import { IColumn, IData, ITableMetaData } from "../../../../../components/Table/table.interface";
import { stateRegionForTag } from "../../../../../components/Table/utils";
import { Datatables } from "../../../../../components/Table/Datatables";
import useLoginFormStore from "../../../../../store/useLoginFormStore";

interface IAddCNDnModalProps {
  guid_client: string;
  certificates: ICertificate[];
  setBulkUpdatedStarted: () => void;
  canShowRetrieve: boolean;
}

export const RetrieveCertificates = (props: IAddCNDnModalProps) => {
  const toast = useToast();
  const { validate } = useLoginFormStore();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure();
  const [isLoading, setLoadingState] = useState<boolean>(false);
  const [proceedDisabled, setProceedDisabled] = useState<boolean>(true);
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  
  // Certificate limit
  const limitFixed = 4;
  const [certificateLimit, setCertificateLimit] = useState<number>(limitFixed);

  // Filter and certificate data
  const [cndData, setCndData] = useState<IData|null>(null);
  const [cndDataToSearch, setCndDataToSearch] = useState<ICNDListData[]>([]);
  const [cndDataTotal, setCndDataTotal] = useState<number>(0);

  const [metaData, setMetaData] = useState<ITableMetaData|undefined>();

  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);

  useEffect(() => {
    if (filterDataSelected) {
      const areFiltersSelected = filterRows.some(row => {
        return row.rowFields.some(field => {
          return filterDataSelected.hasOwnProperty(field.name) && filterDataSelected[field.name].length > 0;
        });
      });

      if(areFiltersSelected) {
        fetchListCndData();
        setProceedDisabled(false);
      } else {
        setCndData(null);
        setCndDataTotal(0);
        setProceedDisabled(true);
      }
    } else {
      setCndData(null);
      setCndDataTotal(0);
      setProceedDisabled(true);
    }
  }, [filterDataSelected, metaData]);

  useEffect(() => {
    fetchCertificateLimit();
  }, []);

  const retrieve = async () => {
    setLoadingState(true);
    if (certificateLimit < limitFixed || validate?.email.includes('@taxly.com.br')) {
      const guids = cndDataToSearch.map((cnd) => cnd.guid_cnd);
      const { status, response } = await retrieveCertificaesByGuidCnd(props.guid_client, guids);
      if (status === 200) {
        props.setBulkUpdatedStarted();
        onCloseConfirmation();
        onClose();

      } else if (status === 401) {
        toast({
          title: 'Limite de consultas excedidas',
          description: 'Você atingiu a cota mensal de 4 consultas.',
          status: 'warning',
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Limite de consultas excedidas',
        description: 'Você atingiu a cota mensal de 4 consultas.',
        status: 'warning',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const fetchListCndData = async () => {
    setLoadingState(true);
  
    const filters = getFilter();
    const { status, response } = await listCnds(props.guid_client, filters);

    if (status === 200 && 'meta' in response) {

      if (response.meta.pageCount !== undefined && response.data !== undefined) {
        setCndData(response);
        setCndDataTotal(response.meta.totalCount);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
    setLoadingState(false);
  }

  const fetchCndDataToSearch = async () => {
    let filters = getFilter();
    filters = {
      ...filters,
      page: 1,
      row_by_page: -1,
    };

    const { status, response } = await listCnds(props.guid_client, filters);

    if (status === 200 && 'meta' in response) {
      if (response.meta.pageCount !== undefined && response.data !== undefined) {
        const data: ICNDListData[] = response.data;

        setCndDataToSearch(data);
      } else {
        toast({
          title: 'Ocorreu um erro',
          description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
          status: 'error',
          duration: 5000,
          isClosable: true
        });
      }
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações das certidões',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }
  }

  const fetchCertificateLimit = async () => {
    setLoadingState(true);
    const { status, response } = await getCertificateLimit(props.guid_client);

    if (status === 200) {
      setCertificateLimit(response);
    } else {
      toast({
        title: 'Ocorreu um erro',
        description: 'Ocorreu um erro ao tentar puxar as informações de consulta de certidão',
        status: 'error',
        duration: 5000,
        isClosable: true
      });
    }

    setLoadingState(false);
  }

  const getFilter = (): IFilterListCertificate => {

    const validFilterField = (field: string | string[] | undefined): boolean => {
      if (field) {
        if (typeof field === 'string') {
          if (field.trim() !== '') {
            return true;
          }
        } else if (field instanceof Array) {
          if (field.length > 0) {
            return true;
          }
        }
      }
      return false;
    }

    const getValidUntilDates = (validUntilRules: string[]) => {

      let startDueDate;
      let endDueDate;

      if (validUntilRules.length > 0) {
        switch (validUntilRules[0]) {
          case "not-valid":
            return { 
              startDueDate: startDueDate, 
              endDueDate: moment.utc().format('YYYY-MM-DD')
            };
          case "15-days":
            return { 
              startDueDate: moment.utc().format('YYYY-MM-DD'), 
              endDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD')
            };
          case "15-days-more":
            return { 
              startDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate 
            };
          case "30-days-more":
            return { 
              startDueDate: moment.utc().add(30, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "45-days-more":
            return { 
              startDueDate: moment.utc().add(45, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "60-days-more":
            return { 
              startDueDate: moment.utc().add(60, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "90-days-more":
            return { 
              startDueDate: moment.utc().add(90, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "120-days-more":
            return { 
              startDueDate: moment.utc().add(120, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          default:
            return {startDueDate: startDueDate, endDueDate: endDueDate};
        }
      }
      return {startDueDate: startDueDate, endDueDate: endDueDate};
    }

    const companies: string[] = [];
    const personas: string[] = [];

    if(filterDataSelected && filterDataSelected[FilterTypeEnum.holderFilter]) {
      filterDataSelected[FilterTypeEnum.holderFilter].forEach((entity) => {
        if (entity.includes('company_')) {
          companies.push(entity.replace('company_', ''));
        } else if (entity.includes('persona_')) {
          personas.push(entity.replace('persona_', ''));
        }
      });
    }
    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];
    const companyTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter] : [];
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const certificateTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateTypeFilter] : [];
    const statuses: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStatusFilter] : [];
    const {startDueDate, endDueDate} = getValidUntilDates(filterDataSelected ? filterDataSelected[FilterTypeEnum.validUntilFilter] : []);

    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const states: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStateFilter] : [];
    const cities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateCityFilter] : [];

    const filters: IFilterListCertificate = {
      page: metaData?.currentPage ?? 1,
      row_by_page: metaData?.rowByPage ?? -1,
      order_field: metaData?.orderField ?? 'createdAt',
      order_direction: metaData?.orderDirection ?? 'desc',
      can_search_cert: '1',
      displayLastValidCertificate: false,
      ...(validFilterField(companies) ? { companies: companies.join(',') } : {}),
      ...(validFilterField(personas) ? { personas: personas.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(certificateTypes) ? { slugs: certificateTypes.join(',') } : {}),
      ...(validFilterField(startDueDate) ? { initial_due_date: startDueDate } : {}),
      ...(validFilterField(endDueDate) ? { final_due_date: endDueDate } : {}),
      
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(states) ? { id_states: states.join(',') } : {}),
      ...(validFilterField(cities) ? { id_cities: cities.join(',') } : {}),
    };

    if(validFilterField(statuses)) {
      const statusValues: string[] = []; 
      statuses.forEach((status) => {
        const statusSplited = status.split('_');
        const stat = statusSplited[statusSplited.length - 1];

        if (!statusValues.some((s) => s === stat)) {
          statusValues.push(stat);
        }
      });

      const certificateValues: string[] = [];
      statuses.forEach((status) => {
        const statusName = status.split('_')[0];

        let certs = props.certificates.map((c) => c.slug);
        if (certificateTypes.length > 0) {
          certs = certificateTypes;
        }

        certs.forEach((cert) => {
          const c = props.certificates.find((c) => c.slug === cert);

          if (c?.status?.some((s) => s.name === statusName) && !certificateValues.some((c) => c === cert)) {
            certificateValues.push(cert);
          }
        });
      });

      filters.status = statusValues.join(',');
      if (validFilterField(certificateValues)) {
        filters.slugs = certificateValues.join(',');
      }
    }

    return filters;
  }

  const openModal = () => {
    fetchCertificateLimit();
    onOpen();
  }

  const closeModal = () => {
    onClose();
  }

  const validateProceedButton = () => {
    return isLoading || proceedDisabled || (cndData ? cndDataTotal === 0 : true);
  }

  const onOpenConfirmationModal = async () => {
    setProceedLoading(true);
    await fetchCndDataToSearch();
    setProceedLoading(false);
    onOpenConfirmation();
  };

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.holderFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyOrPersonaTypeFilter,
          position: 3,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 4,
          size: 2,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.certificateTypeFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateStatusFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.validUntilFilter,
          position: 3,
          size: 6,
        },
      ],
      position: 2
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateStateFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.certificateCityFilter,
          position: 3,
          size: 6,
        },
      ],
      position: 3
    }
  ] as FilterRow[];
  
  const columns = [
    {
      title: 'Consulta',
      name: 'certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: statusForTagList
      },
    },
    {
      title: 'Empresa - (Cód.)',
      name: 'entity.displayName',
      orderFieldName: 'company.name,persona.name',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Tipo',
      name: 'entity.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          textColor: '#365314',
          color: '#ECFCCB',
        },
        {
          text: 'Filial',
          value: 1,
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Pessoa Física',
          value: 3,
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
      ],
    },
    {
      title: 'CNPJ',
      name: 'entity.documentFormatted',
      orderFieldName: 'company.cnpj,persona.document',
      sortable: true,
      align: 'center',
      icon: (row: ICNDListData) => {
        if(!row.errorMessage) {
          return {
            tooltipText: '',
            iconUrl: '',
            show: false
          }
        }

        return {
          tooltipText: row.errorMessage,
          iconUrl: '/icons/alert.svg',
          show: true  
        }
      }
    },
    {
      title: 'Prazo',
      name: 'displayDeadline',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
      textColor: (row: ICNDListData) => {
        if(row.deadline && row.deadline < 30) {
          return '#fc1616';
        }
        return '#171923';
      }
    },
    {
      title: 'Origem',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Estado',
      name: 'state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    
  ] as IColumn[];

  return (
    <Flex>
      <ButtonGroup onClick={openModal} size="sm" mt="24px" isAttached color="white">
        <Button leftIcon={<ReloadIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }} disabled={!props.canShowRetrieve} isDisabled={!props.canShowRetrieve}>
          Atualizar Certidões
        </Button>
      </ButtonGroup>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeModal} size="7xl" >
        <RetrieveCertificatesStyle>
          <ModalOverlay className="modal-overlay" />
          <ModalContent width={1487} className="modal-content" >
            <ModalHeader width='100%' padding="8px 24px !important">
              <Text className="title">Atualizar Certidões em Lote</Text>
              <Text className="description">
                <div className="description-text">
                  <Flex className="blue-text">Selecione abaixo os critérios para atualização das certidões. A lista de certidões exibida abaixo será atualizada de acordo com os filtros aplicados.</Flex>
                </div>
                <div className="limit-box" style={{display: 'flex'}}>
                  <Flex className="limit-message" marginRight="5px">
                    <p>Restam <span className="blue-text"> {validate?.email.includes('@taxly.com.br') ? '∞' : limitFixed - certificateLimit} de {validate?.email.includes('@taxly.com.br') ? '∞' : limitFixed}</span> atualizações mensais disponíveis.</p>
                  </Flex>
                  <Tooltip label='Por padrão, a taxly permite até 04 atualizações de certidões em lote por mês. Caso deseje aumentar esse limite, entre em contato com suporte@taxly.com.br'>
                    <Image height="20px" width="20px" src="/icons/information-circle-blue.svg" resize="none"/>
                  </Tooltip>
                </div>
              </Text>
              { 
              certificateLimit >= limitFixed ? 
                <Flex margin="12px 0" width={1389} border='2px solid #268DF4' background="#F4F4FB" display="flex" alignItems="center" justifyContent="flex-start">
                <Image margin="16px 24px" height="24px" width="24px" src="/icons/information-circle-blue.svg" resize="none"/>

                <Text fontFamily="Poppins-Medium" color="#454954" fontSize="14px" fontWeight="400">Você ultrapassou a sua cota. Entre em contato no e-mail <a style={{color: "blue"}} href="mailto:suporte@taxly.com.br">suporte@taxly.com.br</a> para maiores dúvidas.</Text>
              </Flex>
              : <></>
              }

            </ModalHeader>

            <ModalCloseButton /> 

            <ModalBody fontFamily="Poppins-Medium">
              <Filters 
                guid_client={props.guid_client}
                filters={filterRows}
                certificates={props.certificates}
                selectedValuesHook={setFilterDataSelected}
              />

              {proceedDisabled ? 
              (
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  mt={4}
                >
                  <Text
                    style={{
                      fontFamily: 'Poppins-Medium',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: '140%',
                      color: '#171923'
                    }}
                  >
                    Não há filtros selecionados para exibir registros
                  </Text>
                </Flex>
              ) 
              : (
                <>
                  <Flex flexDirection="column" mt="15px">
                    <Datatables
                      name="Certidões"
                      columns={columns}
                      metaDataHook={setMetaData}
                      data={cndData}
                      isLoading={isLoading}
                      showTotalRows={true}
                      heigth="400px"
                    />
                  </Flex>
                </>
              )}
            </ModalBody> 
            <ModalFooter>
              <Flex width="100%" justifyContent="end" alignItems="flex-end" display="flex">
                <FormButton isLoading={proceedLoading} onClick={onOpenConfirmationModal} width="135px" disabled={validateProceedButton()}>
                  <Tooltip label={proceedDisabled ? "É necessário adicionar algum filtro para prosseguir" : ''}>
                    Prosseguir
                  </Tooltip>
                </FormButton> 
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
        <Modal closeOnOverlayClick={true} isCentered isOpen={isOpenConfirmation} onClose={onCloseConfirmation} size="sm">
          <ModalOverlay className="modal-overlay" />
          <ModalContent flexGrow={1} flexShrink={1} borderLeft="12px solid #0263FF" borderRadius={8}>
            <ModalHeader fontFamily="Poppins-SemiBold">Atualizar Certidões em Lote</ModalHeader>
            <ModalCloseButton />
            <ModalBody fontFamily="Poppins-medium" fontSize={12}>
              <Flex justifyContent="center" flexDirection="column" gap={5}>
                <Flex flexDirection="column" gap={5}>
                  <Text>
                  Ao confirmar, o sistema realizará a pesquisa para  <strong>{cndDataTotal}</strong> {cndDataTotal === 1 ? 'certidão' : 'certidões'}.
                  </Text>
                </Flex>
                <Flex gap={2} flexGrow={1} justifyContent="space-between">
                  <Button isDisabled={isLoading} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={retrieve}>Confirmar</Button>
                  <Button isDisabled={isLoading} bg="#4B4EFF" color="white" fontWeight="thin" fontSize="12px" h="32px" width="50%" onClick={() => onCloseConfirmation()}>Cancelar</Button>
                </Flex>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Modal>
      
    </Flex>
  );
};
