import { AddIcon } from "@chakra-ui/icons";
import { Flex, Modal, ModalOverlay, ModalContent, Button, ButtonGroup, ModalHeader, ModalCloseButton, ModalBody, useDisclosure, Text, Image, ModalFooter, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FormButton } from "../components/login/button.component";
import { RetrieveCertificatesStyle } from "../styles/components/RetrieveCertificates";
import { FilterDataSelected, FilterRow, FilterTypeEnum, Filters } from "../components/Filters";
import { IColumn, ITableMetaData, IData, IDataMeta } from "../components/Table/table.interface";
import { stateRegionForTag } from "../components/Table/utils";
import { Datatables } from "../components/Table/Datatables";
import { filterCertificate, statusForTagList } from "../pages/dashboard/CNDManager/util/cndUtils";
import { ICNDResponse, IFilterListCertificate } from "../_services/interface/cnd.interface";
import moment from "moment";
import { listCnds } from "../_services/cnd.service";
import { getCancelToken } from "../_services/cancelToken";
import { IClientCompanies } from '../_services/interface/company.interface'
import { ICertificate } from "../_services/interface/certificate.interface";
import { getClientCertificates } from "../_services/certificate.service";
import { ICNDListData } from "../_services/interface/cnd.interface";

interface ISelectCertificatesProps {
  guid_client: string;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  open?: boolean;
  setSelectedCertificates: React.Dispatch<React.SetStateAction<ICNDListData[]>>;
  selectedCertificates: ICNDListData[];
  header: string;
  description: string;
  buttonOpenModalText?: string;
  buttonText: string;
  onlyCompanies?: boolean;
  excludeCertificate?: string[];
  callback?: () => void;
}

export const SelectCertificates = (props: ISelectCertificatesProps) => {
  const [filterDataSelected, setFilterDataSelected] = useState<FilterDataSelected|null>(null);
  const toast = useToast();
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [open, setOpen] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<boolean>(false);
  const [showOnlySelecteds, setShowOnlySelecteds] = useState<boolean>(false);
  const [certificates, setCertificates] = useState<IData|null>(null);
  const [certificatesUse, setCertificatesUse] = useState<ICertificate[]>([]);
  const [allCertificates, setAllCertificates] = useState<ICNDListData[]>([]);
  const [selectedCertificates, setSelectedCertificates] = useState<ICNDListData[]>([]);
  const [selectedCertificatesList, setSelectedCertificatesList] = useState<IData|null>(null);
  const [certificateChecked, setCertificateChecked] = useState<string[]>([]);
  const [certificateMetaData, setCertificateMetaData] = useState<ITableMetaData|undefined>({
    currentPage: 1,
    totalPages: 1,
    rowByPage: 10,
    orderField: 'createdAt',
    orderDirection: 'desc'
  });
  const [selectedCertificateMetaData, setSelectedCertificateMetaData] = useState<ITableMetaData|undefined>();

  useEffect(() => {
    if (props.open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [props.open]);

  useEffect(() => {
    if (open) {
      getListCertificates();
      onOpen();

      if(props.setOpen) {
        props.setOpen(true)
      }

      setSelectedCertificateMetaData(undefined);
      setCertificateChecked([]);
      setSelectedCertificatesList(null);
      setSelectedCertificates([]);
      setShowOnlySelecteds(false);
    } else {
      onClose();

      if(props.setOpen) {
        props.setOpen(false)
      }
    }
  }, [open]);

  useEffect(() => {
    const guids = props.selectedCertificates.map((certificate) => certificate.guid_cnd);

    setCertificateChecked(guids);
  }, [props.selectedCertificates]);

  useEffect(() => {
    const selected = allCertificates.filter(cert => certificateChecked.includes(cert.guid_cnd));

    setSelectedCertificates(prevSelected => {
      const newSelected = prevSelected.filter(cert => certificateChecked.includes(cert.guid_cnd));
      const selectedSet = new Set(selected.map(cert => cert.guid_cnd));
      const uniqueNewSelected = newSelected.filter(cert => !selectedSet.has(cert.guid_cnd));

      return [...uniqueNewSelected, ...selected];
    });
  }, [allCertificates, certificateChecked]);

  const getListCertificates = async () => {
    const { status, response } = await getClientCertificates(props.guid_client);
    if (status === 200) {
      setCertificatesUse(response as ICertificate[]);
    }
  };

  const orderCertificateRows = (certificates: ICNDListData[]): ICNDListData[] => {
    const orderField = selectedCertificateMetaData?.orderField as keyof ICNDListData;
    const orderDirection = selectedCertificateMetaData?.orderDirection;

    let orderedCertificates = certificates;

    const compareFunction = (a: ICNDListData, b: ICNDListData): number => {
      const aValue = a[orderField];
      const bValue = b[orderField];

      if (!aValue|| !bValue) {
        return 0;
      }

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const comparison = aValue.localeCompare(bValue);
        return orderDirection === 'asc' ? comparison : -comparison;
      }

      if (aValue < bValue) {
        return orderDirection === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return orderDirection === 'asc' ? 1 : -1;
      }
      return 0;
    };

    orderedCertificates.sort(compareFunction);

    return orderedCertificates;
  }

  useEffect(() => {
    const filteredCertificates = filterCertificate(selectedCertificates, filterDataSelected);
    const orderedCertificates = orderCertificateRows(filteredCertificates);
    const totalPages: number = Math.round(orderedCertificates.length/10);

    let meta: IDataMeta = {
      currentPage: 1,
      isFirstPage: true,
      isLastPage: totalPages === 1,
      nextPage: totalPages > 1 ? 2 : null,
      pageCount: totalPages,
      previousPage: null,
      totalCount: orderedCertificates.length,
    }

    let currentRowByPage = 10;

    if(selectedCertificateMetaData) {
      const { currentPage, totalPages, rowByPage } = selectedCertificateMetaData;

      currentRowByPage = rowByPage;
      
      meta = {
        currentPage,
        isFirstPage: currentPage === 1,
        isLastPage: currentPage === totalPages,
        nextPage: currentPage === totalPages ? null : currentPage + 1,
        pageCount: Math.ceil(orderedCertificates.length / rowByPage),
        previousPage: currentPage === 1 ? null : currentPage - 1,
        totalCount: orderedCertificates.length
      }
    }

    const data = orderedCertificates;
    const start = (meta.currentPage - 1) * currentRowByPage;
    const end = start + currentRowByPage;
    const paginatedData = data.slice(start, end);
    
    setSelectedCertificatesList({
      meta: meta,
      data: paginatedData
    })
  }, [selectedCertificates, selectedCertificateMetaData])

  const selectAll = () => {
    let allCertificatesChecked: boolean = true;
    const selectedSet = new Set(certificateChecked);

    allCertificates.forEach((certificate) => {
      if (!selectedSet.has(certificate.guid_cnd)) {
        allCertificatesChecked = false;
      }
    });

    if (allCertificatesChecked) {
      setCertificateChecked([]);
    } else {
      allCertificates.forEach((certificate) => {
        selectedSet.add(certificate.guid_cnd);
      });
      setCertificateChecked(Array.from(selectedSet));
    }
  }

  const handleCheckboxChange = (selecteds: string[]) => {
    setCertificateChecked(selecteds);
  };

  const submitForm = () => {
    props.setSelectedCertificates(selectedCertificates);
    setOpen(false);

    if (props.callback) {
      props.callback();
    }
  }

  const fetchListCndData = async () => {
    try {
      if (props.guid_client) {
        setLoadingState(true);

        const filters = getFilter();

        const { status, response } = await listCnds(
          props.guid_client,
          filters,
          getCancelToken('selectCertificate.fetchListCndData')
        );

        if (status === 200 && 'meta' in response) {
          if (response.meta.pageCount !== undefined && response.data !== undefined) {
            setCertificates(response);
            setAllCertificates(response.data);
          }
        }

        setTimeout(() => {
          setLoadingState(false);
        }, 1000);
      }
    } catch (error) {
      setLoadingState(false);
    }
  }

  useEffect(() => {
    fetchListCndData();
  }, [
    certificateMetaData,
    props.guid_client,
    filterDataSelected,
  ]);

  const getFilter = (): IFilterListCertificate => {

    const validFilterField = (field?: string | string[]): boolean => {
      return Array.isArray(field) ? field.length > 0 : !!field?.trim();
    }

    const getValidUntilDates = (validUntilRules: string[]) => {

      let startDueDate;
      let endDueDate;

      if (validUntilRules.length > 0) {
        switch (validUntilRules[0]) {
          case "not-valid":
            return { 
              startDueDate: startDueDate, 
              endDueDate: moment.utc().format('YYYY-MM-DD')
            };
          case "15-days":
            return { 
              startDueDate: moment.utc().format('YYYY-MM-DD'), 
              endDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD')
            };
          case "15-days-more":
            return { 
              startDueDate: moment.utc().add(15, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate 
            };
          case "30-days-more":
            return { 
              startDueDate: moment.utc().add(30, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "45-days-more":
            return { 
              startDueDate: moment.utc().add(45, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "60-days-more":
            return { 
              startDueDate: moment.utc().add(60, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "90-days-more":
            return { 
              startDueDate: moment.utc().add(90, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          case "120-days-more":
            return { 
              startDueDate: moment.utc().add(120, 'days').format('YYYY-MM-DD'), 
              endDueDate: endDueDate
            };
          default:
            return {startDueDate: startDueDate, endDueDate: endDueDate};
        }
      }
      return {startDueDate: startDueDate, endDueDate: endDueDate};
    }

    const companyValues: string[] = [];
    const personaValues: string[] = [];

    if(filterDataSelected && filterDataSelected[FilterTypeEnum.holderFilter]) {
      filterDataSelected[FilterTypeEnum.holderFilter].forEach((entity) => {
        if (entity.includes('company_')) {
          companyValues.push(entity.replace('company_', ''));
        } else if (entity.includes('persona_')) {
          personaValues.push(entity.replace('persona_', ''));
        }
      });
    }

    const companyGroups: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyGroupFilter] : [];

    let companyTypes: string[] = filterDataSelected ? (filterDataSelected[FilterTypeEnum.companyOrPersonaTypeFilter] || filterDataSelected[FilterTypeEnum.companyTypeFilter]) : [];
    if(!companyTypes || companyTypes.length === 0) {
      companyTypes = ['4'];
    }

    if(props.onlyCompanies) {
      companyTypes = companyTypes.filter((value) => value !== '3');
    }
    const companyCodes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.companyCodeFilter] : [];

    const statuses: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStatusFilter] : [];
    let certificateTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateTypeFilter] : [];

    const {startDueDate, endDueDate} = getValidUntilDates(filterDataSelected ? filterDataSelected[FilterTypeEnum.validUntilFilter] : []);

    const jurisdictions: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.jurisdictionFilter] : [];
    const states: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateStateFilter] : [];
    const cities: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.certificateCityFilter] : [];
    const searchTypes: string[] = filterDataSelected ? filterDataSelected[FilterTypeEnum.searchTypeFilter] : [];

    const filters: IFilterListCertificate = {
      page: certificateMetaData?.currentPage ?? 1,
      row_by_page: certificateMetaData?.rowByPage ?? -1,
      order_field: certificateMetaData?.orderField ?? 'createdAt',
      order_direction: certificateMetaData?.orderDirection ?? 'desc',
      ...(validFilterField(companyValues) ? { companies: companyValues.join(',') } : {}),
      ...(validFilterField(personaValues) ? { personas: personaValues.join(',') } : {}),
      ...(validFilterField(companyGroups) ? { company_group: companyGroups.join(',') } : {}),
      ...(validFilterField(companyTypes) ? { company_types: companyTypes.join(',') } : {}),
      ...(validFilterField(companyCodes) ? { company_code: companyCodes.join(',') } : {}),

      ...(validFilterField(certificateTypes) ? { slugs: certificateTypes.join(',') } : {}),
      ...(validFilterField(startDueDate) ? { initial_due_date: startDueDate } : {}),
      ...(validFilterField(endDueDate) ? { final_due_date: endDueDate } : {}),
      
      ...(validFilterField(jurisdictions) ? { jurisdiction: jurisdictions.join(',') } : {}),
      ...(validFilterField(states) ? { id_states: states.join(',') } : {}),
      ...(validFilterField(cities) ? { id_cities: cities.join(',') } : {}),
      ...(validFilterField(searchTypes)   ? { is_manual: searchTypes.join(',') } : {}),
      ...(props.excludeCertificate ? {excludeGuids: props.excludeCertificate.join(',')} : {})
    };

    if(validFilterField(statuses)) {
      const statusValues: string[] = []; 
      statuses.forEach((status) => {
        const statusSplited = status.split('_');
        const stat = statusSplited[statusSplited.length - 1];

        if (!statusValues.some((s) => s === stat)) {
          statusValues.push(stat);
        }
      });

      const certificateValues: string[] = [];
      statuses.forEach((status) => {
        const statusName = status.split('_')[0];

        let certs = certificates?.data.map((c) => c.slug);
        if (certificateTypes.length > 0) {
          certs = certificateTypes;
        }

        certs?.forEach((cert) => {
          const c = certificates?.data.find((c) => c.slug === cert);

          if (c?.status  === statusName && !certificateValues.some((c) => c === cert)) {
            certificateValues.push(cert);
          }
        });
      });

      filters.status = statusValues.join(',');
      if (validFilterField(certificateValues)) {
        filters.slugs = certificateValues.join(',');
      }
    }

    return filters;
  }

  const filterRows = [
    {
      rowFields: [
        {
          name: FilterTypeEnum.holderFilter,
          position: 1,
          size: 6,
        },
        {
          name: FilterTypeEnum.companyGroupFilter,
          position: 2,
          size: 2,
        },
        {
          name: props.onlyCompanies ? FilterTypeEnum.companyTypeFilter : FilterTypeEnum.companyOrPersonaTypeFilter,
          position: 3,
          size: 2,
        },
        {
          name: FilterTypeEnum.companyCodeFilter,
          position: 4,
          size: 2,
        },
      ],
      position: 1
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.certificateTypeFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateStatusFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.validUntilFilter,
          position: 3,
          size: 4,
        },
        {
          name: FilterTypeEnum.searchTypeFilter,
          position: 4,
          size: 2,
        },
      ],
      position: 2
    },
    {
      rowFields: [
        {
          name: FilterTypeEnum.jurisdictionFilter,
          position: 1,
          size: 2,
        },
        {
          name: FilterTypeEnum.certificateStateFilter,
          position: 2,
          size: 4,
        },
        {
          name: FilterTypeEnum.certificateCityFilter,
          position: 3,
          size: 6,
        },
      ],
      position: 3
    }
  ] as FilterRow[];

  const columns = [
    {
      title: 'Certidão',
      name: 'certificate_name',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Status',
      name: 'status.name',
      orderFieldName: 'slug_status',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: statusForTagList
      },
    },
    {
      title: 'Titular',
      name: 'entity.displayName',
      orderFieldName: 'company.name,persona.name',
      sortable: true,
      align: 'center'
    },
    {
      title: 'Tipo',
      name: 'entity.type',
      orderFieldName: 'company.type',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Matriz',
          value: 0,
          textColor: '#365314',
          color: '#ECFCCB',
        },
        {
          text: 'Filial',
          value: 1,
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Pessoa Física',
          value: 3,
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
      ],
    },
    {
      title: 'CNPJ/CPF',
      name: 'entity.documentFormatted',
      orderFieldName: 'company.cnpj,persona.document',
      sortable: true,
      align: 'center',
      icon: (row: ICNDResponse) => {
        if(!row.errorMessage) {
          return {
            tooltipText: '',
            iconUrl: '',
            show: false
          }
        }

        return {
          tooltipText: row.errorMessage,
          iconUrl: '/icons/alert.svg',
          show: true  
        }
      }
    },
    {
      title: 'Grupo',
      name: 'groups_names',
      sortable: false,
      align: 'center',
    },
    {
      title: 'Emissão',
      name: 'issuedAtFormatted',
      orderFieldName: 'issuedAt',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Vencimento',
      name: 'validUntilFormatted',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
    },
    {
      title: 'Prazo',
      name: 'displayDeadline',
      orderFieldName: 'validUntil',
      sortable: true,
      align: 'center',
      textColor: (row: ICNDResponse) => {
        if(row.deadline && row.deadline < 30) {
          return '#fc1616';
        }
        return '#171923';
      }
    },
    {
      title: 'Jurisdição',
      name: 'jurisdiction',
      sortable: true,
      align: 'center',
      tags: [
        {
          text: 'Federal',
          value: 'federal',
          textColor: '#065666',
          color: '#C4F1F9',
        },
        {
          text: 'Municipal',
          value: 'municipal',
          textColor: '#134E4A',
          color: '#CCFBF1',
        },
        {
          text: 'Estadual',
          value: 'state',
          textColor: '#322659',
          color: '#E9D8FD',
        },
      ],
    },
    {
      title: 'Estado',
      name: 'state.initials',
      sortable: true,
      align: 'center',
      dynamicTag: {
        function: stateRegionForTag,
        field: 'state.initials'
      },
    },
    {
      title: 'Cidade',
      name: 'city.name',
      sortable: true,
      align: 'center',
      tags: [
        { 
          color: '#E2E8F0',
          textColor: '#1A202C'
        },
      ]
    },
    
  ] as IColumn[];

  return (
    <>
      {
        !props.setOpen &&
        (
          <ButtonGroup onClick={()=> setOpen(true)} size="sm" mt="24px" isAttached color="white">
            <Button leftIcon={<AddIcon />} bgColor="#4B4EFF" color="white" _hover={{ bg: '#282be0' }}>
              {props.buttonOpenModalText}
            </Button>
          </ButtonGroup>
        )
      }

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={() => setOpen(false)} size="7xl" >
        <RetrieveCertificatesStyle>
          <ModalOverlay className="modal-overlay" />
          <ModalContent width={1487} className="modal-content" >
            <ModalHeader className="header">
              <Text className="title">{props.header}</Text>
              <Text className="description">
                <div>
                  <Flex style={{marginTop: "16px"}} className="blue-text">{props.description}</Flex>
                </div>
              </Text>
              <Flex
                style={{marginTop: "12px"}}
              >
                <Filters
                  guid_client={props.guid_client} 
                  filters={filterRows}
                  selectedValuesHook={setFilterDataSelected}
                  certificates={certificatesUse}
                />
              </Flex>
            </ModalHeader>

            <ModalCloseButton /> 

            <ModalBody fontFamily="Poppins-Medium">
              <hr />
              <Flex position="relative" direction="column">
                <Flex
                  cursor="pointer"
                  position="absolute"
                  mt={8}
                  onClick={() => setShowOnlySelecteds(!showOnlySelecteds)}
                  display={
                    allCertificates.length > 1
                    && selectedCertificates.length > 0
                    ? 'flex' : 'none'}
                >
                  {showOnlySelecteds ? (
                    <Image w="40px" src="/icons/switch-blue.svg" />
                  ) : (
                    <Image w="40px" src="/icons/switch-gray.svg" />
                  )}

                  <Flex direction="column" ml={2}>
                    <Text fontSize="14px">
                        Exibir apenas registros selecionados
                    </Text>
                  </Flex>
                </Flex>

                <Flex alignItems="stretch" direction="column" gap={2} flexGrow={1} mt={8} mb={4} >
                  {
                    showOnlySelecteds
                    ? (
                      <Datatables
                        name="Certidões"
                        columns={columns}
                        metaDataHook={setSelectedCertificateMetaData}
                        data={selectedCertificatesList}
                        isLoading={loadingState}
                        showTotalRows={true}
                        heigth="400px"
                      />
                    ) 
                    : (
                      <Datatables
                        name="Certidões"
                        columns={columns}
                        metaDataHook={setCertificateMetaData}
                        data={certificates}
                        isLoading={loadingState}
                        showTotalRows={true}
                        heigth="400px"
                        checkBox={{
                          selecteds: certificateChecked,
                          setSelecteds: handleCheckboxChange,
                          checkAll: selectAll,
                          key: 'guid_cnd'
                        }}
                      />
                    )
                  }
                </Flex>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex width="100%" flexDirection="column">
                <Flex
                  width="100%"
                  justifyContent="end"
                  mt="20px"
                  alignItems="flex-start"
                  display="flex"
                >
                  <FormButton onClick={submitForm} width="auto">
                    {props.buttonText}
                  </FormButton> 
                </Flex>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </RetrieveCertificatesStyle>
      </Modal>
    </>
  );
}
